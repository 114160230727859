import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  DirectFlightModel,
  FlightTrainModel,
  IndirectFlightModel,
  TrainFlightTrainModel,
  TrainModel,
  TrainTrainModel,
} from "../utils/models_types";

export interface CounterState {
  updatedData: boolean;
  fromCity: string;
  fromState: string;
  toCity: string;
  toState: string;
  adults: number;
  departureDate: string;
  // flightData: Array<Object>;
  // trainData: Array<Object>;
  // trainTrainData: Array<Object>;
  // trainFlightTrainData: Array<Object>;
  // trainFlightTrainIntermediateObject: Object;
  // flightTrainData: Array<Object>;
  // trainFlightData: Array<Object>;
  // flightTrainIntermediateObject: Object;
  // trainFlightIntermediateObject: Object;
  openlogin: boolean;
  mapanimatestart: boolean;
  mapProps: any;

  dateWiseData: dateWiseObject;
}

export interface dateWiseObject {
  [key: string]: {
    flightData?: (DirectFlightModel | IndirectFlightModel)[];
    trainData?: TrainModel[];
    trainTrainData?: TrainTrainModel[];
    trainFlightTrainData?: TrainFlightTrainModel[];
    trainFlightTrainIntermediateObject?: IntermediteTFTObjectType | null;
    flightTrainData?: FlightTrainModel[];
    trainFlightData?: FlightTrainModel[];
    flightTrainIntermediateObject?: IntermediteObjectType | null;
    trainFlightIntermediateObject?: IntermediteObjectType | null;
    trainTrainIntermediateObject?: IntermediteObjectType | null;
  };
}

interface DataAction {
  type: string;
  data:
    | TrainModel[]
    | (DirectFlightModel | IndirectFlightModel)[]
    | FlightTrainModel[]
    | TrainTrainModel[]
    | TrainFlightTrainModel[];
  date?: string;
  intermediate?: string;
}

const initialState: CounterState = {
  updatedData: false,
  fromCity: "",
  openlogin: false,
  mapanimatestart: false,
  mapProps: {},
  fromState: "",
  toCity: "",
  toState: "",
  adults: 1,
  departureDate: "",
  // flightData: [],
  // trainData: [],
  // trainTrainData: [],
  // flightTrainData: [],
  // trainFlightData: [],
  // trainFlightTrainData: [],
  // flightTrainIntermediateObject: {},
  // trainFlightIntermediateObject: {},
  // trainFlightTrainIntermediateObject: {},

  dateWiseData: {},
};

export const counterSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload
    // },
    setUpdatedData: (state, action: PayloadAction<boolean>) => {
      state.updatedData = action.payload;
    },
    setFromCity: (state, action: PayloadAction<string>) => {
      state.fromCity = action.payload;
    },
    setOpenlogin: (state, action: PayloadAction<boolean>) => {
      state.openlogin = action.payload;
    },
    setMapanimatestart: (state, action: PayloadAction<boolean>) => {
      state.mapanimatestart = action.payload;
    },
    setMapProps: (state, action: PayloadAction<any>) => {
      state.mapProps = action.payload;
    },
    setFromState: (state, action: PayloadAction<string>) => {
      state.fromState = action.payload;
    },
    setToCity: (state, action: PayloadAction<string>) => {
      state.toCity = action.payload;
    },
    setToState: (state, action: PayloadAction<string>) => {
      state.toState = action.payload;
    },
    setAdults: (state, action: PayloadAction<number>) => {
      state.adults = action.payload;
    },
    setDepartureDate: (state, action: PayloadAction<string>) => {
      state.departureDate = action.payload;
    },
    emptyDateWiseData: (state) => {
      state.dateWiseData = {};
    },
    setData: (state, action: PayloadAction<DataAction>) => {
      console.log(state.departureDate);
      if (!state.dateWiseData[action.payload.date ?? state.departureDate]) {
        state.dateWiseData[action.payload.date ?? state.departureDate] = {};
      }
      switch (action.payload.type) {
        case "flight":
          state.dateWiseData[
            action.payload.date ?? state.departureDate
          ].flightData = action.payload.data as (
            | DirectFlightModel
            | IndirectFlightModel
          )[];
          break;
        case "train":
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainData"
          ] = action.payload.data as TrainModel[];
          break;
        case "trainTrain":
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainTrainData"
          ] = action.payload.data as TrainTrainModel[];
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainTrainIntermediateObject"
          ] = createIntermediateObject(
            action.payload.data as TrainTrainModel[]
          );
          break;
        case "flightTrain":
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "flightTrainData"
          ] = action.payload.data as FlightTrainModel[];
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "flightTrainIntermediateObject"
          ] = createIntermediateObject(
            action.payload.data as FlightTrainModel[]
          );
          break;
        case "trainFlight":
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainFlightData"
          ] = action.payload.data as FlightTrainModel[];
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainFlightIntermediateObject"
          ] = createIntermediateObject(
            action.payload.data as FlightTrainModel[]
          );
          break;
        case "trainFlightTrain":
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainFlightTrainData"
          ] = action.payload.data as TrainFlightTrainModel[];
          state.dateWiseData[action.payload.date ?? state.departureDate][
            "trainFlightTrainIntermediateObject"
          ] = createIntermediateTFTObject(
            action.payload.data as TrainFlightTrainModel[]
          );
          break;
        default:
          break;
      }
    },
    setDataIntermediate: (state, action: PayloadAction<DataAction>) => {
      const date = action.payload.date ?? state.departureDate;

      // Initialize the date-wise data if not present
      if (!state.dateWiseData[date]) {
        state.dateWiseData[date] = {};
      }

      // Handle different modes and intermediate objects
      switch (action.payload.type) {
        case "trainTrain":
          if (!state.dateWiseData[date].trainTrainIntermediateObject) {
            state.dateWiseData[date].trainTrainIntermediateObject = {};
          }
          state.dateWiseData[date].trainTrainIntermediateObject![
            `${action.payload.intermediate}`
          ] = action.payload.data as TrainTrainModel[];
          break;

        case "flightTrain":
          if (
            state.dateWiseData[date].flightTrainIntermediateObject &&
            action.payload.data
          ) {
            state.dateWiseData[date].flightTrainIntermediateObject![
              `${action.payload.intermediate}`
            ] = action.payload.data as FlightTrainModel[];
          } else {
            console.error(
              "No flightTrain data found to create intermediate object"
            );
          }
          break;

        case "trainFlight":
          if (
            state.dateWiseData[date].trainFlightIntermediateObject &&
            action.payload.data
          ) {
            state.dateWiseData[date].trainFlightIntermediateObject![
              `${action.payload.intermediate}`
            ] = action.payload.data as FlightTrainModel[];
          } else {
            console.error(
              "No trainFlight data found to create intermediate object"
            );
          }
          break;

        case "trainFlightTrain":
          if (
            state.dateWiseData[date].trainFlightTrainIntermediateObject &&
            action.payload.data
          ) {
            state.dateWiseData[date].trainFlightTrainIntermediateObject![
              `${action.payload.intermediate}`
            ] = action.payload.data as TrainFlightTrainModel[];
          } else {
            console.error(
              "No trainFlightTrain data found to create intermediate object"
            );
          }
          break;

        default:
          console.error("Unknown mode specified");
          break;
      }
    },
    // updateFlightTrainIntermediateObject: (state, action: PayloadAction<Object>) => {
    //  state.flightTrainIntermediateObject = action.payload;
    // },
    // updateTrainFlightIntermediateObject: (state, action: PayloadAction<Object>)=> {
    // state.trainFlightIntermediateObject = action.payload;
    // },
  },
});

// interface CombinationObject {
//   intermediate: string;
//   train: Array<Object>;
//   flight: Array<Object>;
// }

// interface CombinationTFTObject {
//   intermediate1: string;
//   train1: Array<Object>;
//   flight: Array<Object>;
//   intermediate2: string;
//   train2: Array<Object>;
// }

interface IntermediteObjectType {
  [key: string]:
    | FlightTrainModel[]
    | TrainTrainModel[]
    | TrainFlightTrainModel[];
}

interface IntermediteTFTObjectType {
  [key: string]: TrainFlightTrainModel[];
}

const createIntermediateObject = (
  data:
    | TrainModel[]
    | (DirectFlightModel | IndirectFlightModel)[]
    | FlightTrainModel[]
    | TrainTrainModel[]
    | TrainFlightTrainModel[]
) => {
  const IntermediteObject: IntermediteObjectType = {};

  data?.forEach((obj) => {
    const intermediate = "intermediate" in obj ? obj.intermediate : "";

    if (!IntermediteObject[intermediate]) {
      IntermediteObject[intermediate] = [];
    }

    if ("intermediate" in obj) {
      (
        IntermediteObject[intermediate] as (
          | FlightTrainModel
          | TrainTrainModel
          | TrainFlightTrainModel
        )[]
      ).push(obj as FlightTrainModel | TrainTrainModel | TrainFlightTrainModel);
    }
  });
  return IntermediteObject;
};

const createIntermediateTFTObject = (data: TrainFlightTrainModel[]) => {
  const IntermediteObject: IntermediteTFTObjectType = {};

  data?.forEach((obj) => {
    const intermediate1 = obj.intermediate1;
    const intermediate2 = obj.intermediate2;
    const intermediate = intermediate1 + "&" + intermediate2;
    if (!IntermediteObject[intermediate]) {
      IntermediteObject[intermediate] = [];
    }
    IntermediteObject[intermediate].push(obj);
  });

  return IntermediteObject;
};

// Action creators are generated for each case reducer function
export const {
  setUpdatedData,
  setFromCity,
  setFromState,
  setToCity,
  setToState,
  setAdults,
  setDepartureDate,
  setData,
  setDataIntermediate,
  emptyDateWiseData,
  setOpenlogin,
  setMapanimatestart,
  setMapProps,
  // updateFlightTrainIntermediateObject,
  // updateTrainFlightIntermediateObject
} = counterSlice.actions;

export default counterSlice.reducer;
