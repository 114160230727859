import { useState, useRef } from "react";
import Train from "../../assets/vectors1/Vector2_x2.svg";
import Down_arrow from "../../assets/vectors1/Down_arrow.svg";
import Reload from "../../assets/vectors1/Reload.svg";
import Arrow_left from "../../assets/vectors1/Arrow_left.svg";
import Arrow_right from "../../assets/vectors1/Arrow_right.svg";

import { getAvailableClassType } from "../../pages/ModesPage";
import { convertToMinutes } from "../../utils/misc";
import { TrainModel } from "../../utils/models_types";
import dashline from "../../assets/vectors/dashline.svg";
import cardtrain from "../../assets/vectors/newtrain.svg";

interface TrainIndividualProps {
  trainComponent: TrainModel;
  fromCity: string;
  toCity: string;
  getFares: (
    train_number: string,
    fromStation: string,
    toStation: string,
    classTypes: string[],
    quota: string
  ) => Promise<void>;
  selectedPrice: number | undefined;
  setSelectedPrice: (price: number) => void;
}

function TrainIndividual({
  trainComponent,
  fromCity,
  toCity,
  getFares,
  selectedPrice,
  setSelectedPrice,
}: TrainIndividualProps) {
  const [selectedClass, setselectedClass] = useState<string>();
  // getAvailableClassType(trainComponent.properties.prices?.general)
  const [quotaOpen, setQuotaOpen] = useState<boolean>(false);
  const [selectedQuota, setSelectedQuota] = useState<string>("general");

  const elementRef = useRef<HTMLDivElement>(null);
  const [arrowDisable, setArrowDisable] = useState<boolean>(true);
  // console.log(trainComponent.properties.from_station_name)
  const handleHorizantalScroll = (
    element: HTMLDivElement,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const convertDurationToHours = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const dateFormat = new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  });

  const formatDate = (inputdate: number): string => {
    return dateFormat.format(new Date(inputdate - 5.5 * 60 * 60));
  };

  const timeFormat = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formatTime = (inputtime: number): string => {
    return timeFormat.format(new Date(inputtime));
  };

  return (
    <div className="train-card">
      <div className="details">
        <div className="timings-container">
          <div className="date-time">
            <p className="date">
              {formatDate(trainComponent.properties.departure_time)}
            </p>
            <div className="time">
              {formatTime(trainComponent.properties.departure_time)}
            </div>
          </div>
          {/* <div className="flex-1 border-[1px] border-dashed border-black"></div> */}
          <div>
            <img src={dashline}></img>
          </div>

          <div className="duration-text">
            {convertDurationToHours(
              convertToMinutes(trainComponent.properties.duration)
            )}
          </div>
          {/* <div className="flex-1 border-[1px] border-dashed border-black"></div> */}
          <div>
            <img src={dashline} className="rotate-180" alt="Rotated image" />
          </div>

          <div className="date-time">
            <div className="time">
              {formatTime(trainComponent.properties.arrival_time)}
            </div>
            <p className="date">
              {formatDate(trainComponent.properties.arrival_time)}
            </p>
          </div>
        </div>
        <div className="train-icon-section flex items-center">
          {/* Top line with dot at the end */}
          <div className="cardiconline line flex flex-col items-center justify-between ">
            <div className="h-[6px] w-[6px] bg-[#005073] rounded-full"></div>
            <div className="flex-grow w-0 border-[1px] border-[#005073]"></div>
          </div>

          {/* Train icon */}
          <div className="train-icon">
            <img src={cardtrain} alt="Train Icon" />
          </div>

          {/* Bottom line with dot at the start */}
          <div className="cardiconline line flex flex-col items-center justify-between ">
            <div className="flex-grow border-[1px] border-[#005073]"></div>
            <div className="h-[6px] w-[6px] bg-[#005073] rounded-full"></div>
          </div>
        </div>

        <div className="train-details">
          <div className="train-top">
            <p className="name">
              <span className="whole-name capitalize">
                <span
                  className="station-name capitalize"
                  title={`${trainComponent.properties.from_station_name?.toLowerCase()}`}
                >
                  {trainComponent.properties.from_station_name?.toLowerCase()}
                </span>{" "}
                , {trainComponent.properties.origin_city?.toLowerCase()}
              </span>
            </p>

            <button
              onClick={() => {
                setQuotaOpen((prev) => !prev);
              }}
              className="quota-dropdown relative"
            >
              <p>General</p>
              <div className="expand-icon">
                <img src={Down_arrow} />
              </div>
              {quotaOpen && (
                <div className="absolute divide-y divide-black border border-black flex flex-col top-[120%] left-0 bg-white rounded-sm text-sm px-2 w-full z-30">
                  <button
                    onClick={() => {
                      setSelectedPrice(
                        //TODO: check this once
                        trainComponent.properties.prices?.general[
                          selectedClass!
                        ] ?? 0
                      );
                      setSelectedQuota("general");
                    }}
                    style={{
                      fontFamily: "Poppins",
                    }}
                    className="text-left pl-2 py-2 pb-1"
                  >
                    General
                  </button>
                  <button
                    onClick={() => {
                      setSelectedPrice(
                        trainComponent.properties.prices?.tatkal[
                          selectedClass!
                        ] ?? 0
                      );
                      setSelectedQuota("tatkal");
                    }}
                    style={{
                      fontFamily: "Poppins",
                    }}
                    className="text-left pl-2 py-2 pt-1"
                  >
                    Taktal
                  </button>
                </div>
              )}
            </button>
          </div>
          <div className="traincard-mid">
            <div className="train-name-container">
              <p>
                <span>#{trainComponent.properties.train_number}</span>
                <span
                  className="train-name capitalize"
                  title={`${trainComponent.properties.train_name}`}
                >
                  {trainComponent.properties.train_name}
                </span>
              </p>

              <div
                className="reload-btn"
                onClick={() => {
                  getFares(
                    trainComponent.properties.train_number,
                    trainComponent.properties.station_from,
                    trainComponent.properties.station_to,
                    trainComponent.properties.class_type,
                    selectedQuota
                  );
                }}
              >
                <img className="reload" src={Reload} />
              </div>
            </div>

            <div className="coach-container">
              <button
                onClick={() => {
                  handleHorizantalScroll(elementRef.current!, 25, 100, -10);
                }}
                disabled={arrowDisable}
              >
                <img src={Arrow_left} />
              </button>
              <div className="coach-list" ref={elementRef}>
                {trainComponent.properties.class_type &&
                  trainComponent.properties.class_type.map((classtype, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        if (
                          !(
                            trainComponent.properties.prices &&
                            trainComponent.properties.prices[
                              selectedQuota as "general" | "tatkal"
                            ]
                          )
                        ) {
                          getFares(
                            trainComponent.properties.train_number,
                            trainComponent.properties.station_from,
                            trainComponent.properties.station_to,
                            trainComponent.properties.class_type,
                            selectedQuota
                          );
                        } else {
                          setselectedClass(classtype);
                          setSelectedPrice(
                            trainComponent.properties.prices[
                              selectedQuota as "general" | "tatkal"
                            ][classtype]
                          );
                        }
                      }}
                      className={`
                       train-class-card ${
                         trainComponent.properties.prices &&
                         trainComponent.properties.prices[
                           selectedQuota as "general" | "tatkal"
                         ]
                           ? selectedClass == classtype
                             ? "train-class-card-active"
                             : ""
                           : "train-class-card-disabled"
                       }`}
                    >
                      <div className="class-card-top">
                        <p>{classtype}</p>
                        <p>
                          ₹
                          {trainComponent.properties.prices &&
                          selectedQuota in trainComponent.properties.prices &&
                          classtype in
                            trainComponent.properties.prices[
                              selectedQuota as "general" | "tatkal"
                            ]
                            ? trainComponent.properties.prices[
                                selectedQuota as "general" | "tatkal"
                              ][classtype]
                            : "--"}
                        </p>
                      </div>
                      <p className="green-text">
                        {trainComponent.properties.prices &&
                        (selectedQuota == "general" ||
                          selectedQuota == "tatkal") &&
                        trainComponent.properties.prices[selectedQuota] ? (
                          trainComponent.properties.availability?.[
                            selectedQuota as "general" | "tatkal"
                          ][classtype]?.status.split("-")[0] == "AVAILABLE" ? (
                            <span>
                              {"Available: " +
                                parseInt(
                                  trainComponent.properties.availability?.[
                                    selectedQuota as "general" | "tatkal"
                                  ][classtype]?.status.split("-")[1] ?? "0"
                                )}
                            </span>
                          ) : trainComponent.properties.availability?.[
                              selectedQuota as "general" | "tatkal"
                            ][classtype]?.status.includes("/") ? (
                            <span className="text-[#FD9754]">
                              {trainComponent.properties.availability?.[
                                selectedQuota as "general" | "tatkal"
                              ][classtype]?.status
                                .split("/")[1]
                                .slice(0, -1)}
                            </span>
                          ) : (
                            <span className="text-gray-800">
                              {
                                trainComponent.properties.availability?.[
                                  selectedQuota as "general" | "tatkal"
                                ][classtype]?.status
                              }
                            </span>
                          )
                        ) : (
                          "Refresh"
                        )}
                      </p>
                      {trainComponent.properties.availability?.[
                        selectedQuota as "general" | "tatkal"
                      ][classtype]?.probablity == undefined ||
                      trainComponent.properties.availability?.[
                        selectedQuota as "general" | "tatkal"
                      ][classtype]?.probablity == "" ? (
                        <p className="green-text">--</p>
                      ) : (
                        <p
                          style={{
                            color:
                              trainComponent.properties.availability?.[
                                selectedQuota as "general" | "tatkal"
                              ][classtype]?.probablity == "High"
                                ? "#0c8d57"
                                : trainComponent.properties.availability?.[
                                    selectedQuota as "general" | "tatkal"
                                  ][classtype]?.probablity == "Med"
                                ? "#FD9754"
                                : "#dc2626",
                          }}
                          className={`green-text`}
                        >
                          {
                            trainComponent.properties.availability?.[
                              selectedQuota as "general" | "tatkal"
                            ][classtype].probablity_percent
                          }
                          % chances
                        </p>
                      )}
                    </div>
                  ))}
              </div>
              <button
                onClick={() => {
                  handleHorizantalScroll(elementRef.current!, 25, 100, 10);
                }}
              >
                <img src={Arrow_right} />
              </button>
            </div>
          </div>
          <div className="train-bottom">
            <p className="name">
              <span className="whole-name capitalize">
                <span
                  className="station-name capitalize"
                  title={`${trainComponent.properties.to_station_name?.toLowerCase()}`}
                >
                  {trainComponent.properties.to_station_name?.toLowerCase()}
                </span>{" "}
                , {trainComponent.properties.destination_city?.toLowerCase()}{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainIndividual;
