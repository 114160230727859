import { useState } from "react";
import TrainIndividual from "../cards/traincard";
import info from "../../assets/vectors/bx-info-circle.svg";
import People from "../../assets/vectors/Vector75_x2.svg";
import { useSelector } from "react-redux";
import { convertToMinutes } from "../../utils/misc";
import { RootState } from "../../store/store";
import { TrainModel } from "../../utils/models_types";
export default function TrainCard({
  train,
  fromCity,
  toCity,
  getFares,
}: {
  train: TrainModel;
  fromCity: string;
  toCity: string;
  getFares: (
    train_number: string,
    fromStation: string,
    toStation: string,
    classTypes: string[],
    quota: string
  ) => Promise<void>;
}) {
  const adults = useSelector<RootState, number>((state) => state.data.adults);
  const convertDurationToHours = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return `${hours}h ${remainingMinutes}m`;
  };

  const [selectedPrice, setSelectedPrice] = useState(train.total_price);

  return (
    <div>
      <TrainIndividual
        trainComponent={train}
        fromCity={fromCity}
        toCity={toCity}
        getFares={getFares}
        selectedPrice={selectedPrice}
        setSelectedPrice={setSelectedPrice}
      />
      <div className="card-bottom">
        <div className="bottom-duration">
          <p>
            Journey Duration :{" "}
            <span className="text-[#F1C40F] font-regular">
              {/* 11h 03 */}
              {convertDurationToHours(
                convertToMinutes(train.properties.duration)
              )}
            </span>
          </p>
        </div>
        <div className="card-price">
          <p>
            <span>Total Price : </span>
            <span className="text-[#F1C40F] font-regular">
              {/* ₹1234 */}
              {selectedPrice
                  ? "₹" + selectedPrice * adults
                  : "Select Train Class"}
            </span>
          </p>
          <img className="text-[#F1C40F]" src={info} />
          {/* <div className="wrapper-adults">
            <div className="adults-count">
              <div className="adults-count-no">
                <span className="container-tra">{adults}</span>
              </div>
              <img className="vector-tra" src={People} />
            </div>
          </div> */}
        </div>
        <button
          disabled={!selectedPrice}
          // disabled:saturate-0
          className="card-book-btn  disabled:cursor-not-allowed"
        >
          Select
        </button>
      </div>
    </div>
  );
}
