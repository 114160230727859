import Train from "../../assets/vectors/Vector37_x2.svg";
import Plane from "../../assets/images/flight.png";
import Leftarrow from "../../assets/vectors/Vector101_x2.svg";
import Rightarrow from "../../assets/vectors/Vector103_x2.svg";
import { format, parseISO, addDays, subDays } from "date-fns";
import result from "../../assets/vectors/eva_list-fill.svg";
import "../../style/global.css";
import train from "../../assets/vectors/newtrain.svg";
import flight from "../../assets/vectors/newflight.svg";
import {
  DirectFlightModel,
  FlightTrainModel,
  IndirectFlightModel,
  TrainFlightTrainModel,
  TrainModel,
  TrainTrainModel,
} from "../../utils/models_types";

// Define the types for the props passed to the Header function
interface HeaderProps {
  fromCity: string;
  toCity: string;
  intermediate?: string;
  intermediate2?: string;
  mode: string;
  departureDate: string;
  minDateData:  TrainModel | DirectFlightModel | IndirectFlightModel | FlightTrainModel | TrainTrainModel | TrainFlightTrainModel;
  length :number  
}

// // Helper functions to get next and previous day
// function getNextDay(dateString: string): string {
//   const date = parseISO(dateString);
//   const nextDay = addDays(date, 1);
//   return format(nextDay, "yyyy-MM-dd");
// }

// function getPrevDay(dateString: string): string {
//   const date = parseISO(dateString);
//   const prevDay = subDays(date, 1);
//   return format(prevDay, "yyyy-MM-dd");
// }
const formatDay = (dateString: number | string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", { weekday: "short" });
};

const formatDateMonth = (dateString: number | string) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "short" });
  const dayOfMonth = date.getDate();

  const nth = (d: number) => {
    if (d > 3 && d < 21) return "ᵗʰ";
    switch (d % 10) {
      case 1:
        return "ˢᵗ";
      case 2:
        return "ⁿᵈ";
      case 3:
        return "ʳᵈ";
      default:
        return "ᵗʰ";
    }
  };

  return `${dayOfMonth}${nth(dayOfMonth)} ${month}`;
};
// Header component accepts HeaderProps as props
export default function Header({
  fromCity,
  toCity,
  intermediate = "",
  intermediate2 = "",
  mode,
  departureDate,
  minDateData,
  length,
}: HeaderProps) {
  // const [date, setDate] = useState<Date>(new Date(departureDate));
  // if (intermediate) {
  //   intermediate =
  //     intermediate.charAt(0).toUpperCase() +
  //     intermediate.substring(1)?.toLowerCase();
  // }

  // if (intermediate2) {
  //   intermediate2 =
  //     intermediate2.charAt(0).toUpperCase() +
  //     intermediate2.substring(1)?.toLowerCase();
  // }

  return (
    <div className="headeroutline">
      <div className="headerinnerline">
        <div className="headermodecards">
          <div className="modedetails">
            <div className="innermodedetails">
              <div
                className={`citynameleft ${
                  mode === "trainFlightTrain" ? "w-[75px]" : "flex-1"
                }`}
              >
                <span className="citynamelefttext capitalize">
                  {fromCity?.toLowerCase()}
                </span>
              </div>
              <div className="onemodetravel">
                <div className="onemodetravelinner">
                  {/* <img src={leftline}></img> */}
                  <div
                    className={`left-line ${
                      mode === "train" || mode === "flight"
                        ? "w-[83px]"
                        : mode === "trainFlightTrain"
                        ? "w-[49px]"
                        : "w-[69px]"
                    }`}
                  ></div>
                  {/* <img src={flight}></img> */}
                  <img
                    // className="vector-6"
                    src={
                      mode === "flight" || mode === "flightTrain"
                        ? flight
                        : train
                    }
                    alt="plane or train icon"
                  />
                  {/* <img src={rightline}></img> */}
                  <div
                    className={`right-line ${
                      mode === "train" || mode === "flight"
                        ? "w-[83px]"
                        : mode === "trainFlightTrain"
                        ? "w-[49px]"
                        : "w-[69px]"
                    }`}
                  ></div>
                </div>
                <div
                  className={`dateandday ${
                    mode === "train" || mode === "flight"
                      ? "gap-[116px]"
                      : mode === "trainFlightTrain"
                      ? "gap-[49px]"
                      : "gap-[88px]"
                  }`}
                >
                  <div className="dateanddayinner">
                    <div className="daydivtext">
                      <span className="daytext">
                        {formatDay(departureDate)}
                      </span>
                    </div>
                    <div className="datetextdiv">
                      <span className="datetext">
                        {formatDateMonth(departureDate)}
                      </span>
                    </div>
                  </div>
                  <div className="dateanddayinner">
                    <div className="daydivtext">
                      <span className="daytext">
                        {formatDay(
                          mode === "train"
                            ? (minDateData as TrainModel).properties
                                .arrival_time
                            : mode === "flight" &&
                              (minDateData as DirectFlightModel).properties
                                .stop_count === 0
                            ? (minDateData as DirectFlightModel).properties
                                .arrival_time
                            : mode === "flight" &&
                              (minDateData as IndirectFlightModel).properties
                                .stop_count >= 1
                            ? (minDateData as IndirectFlightModel).properties
                                ?.segments?.[
                                (minDateData as IndirectFlightModel).properties
                                  ?.segments?.length - 1
                              ].arrival_time
                            : departureDate
                        )}
                      </span>
                    </div>
                    <div className="datetextdiv">
                      <span className="datetext">
                        {formatDateMonth(
                          mode === "train"
                            ? (minDateData as TrainModel).properties
                                .arrival_time
                            : mode === "flight" &&
                              (minDateData as DirectFlightModel).properties
                                .stop_count === 0
                            ? (minDateData as DirectFlightModel).properties
                                .arrival_time
                            : mode === "flight" &&
                              (minDateData as IndirectFlightModel).properties
                                .stop_count >= 1
                            ? (minDateData as IndirectFlightModel).properties
                                ?.segments?.[
                                (minDateData as IndirectFlightModel).properties
                                  ?.segments?.length - 1
                              ].arrival_time
                            : departureDate
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {(mode === "flightTrain" ||
                mode === "trainFlight" ||
                mode === "trainFlightTrain" ||
                mode === "trainTrain") && (
                <>
                  <div className="citynamemiddelcontainer">
                    <div className="innercitynamemiddel">
                      <span className="middlecitynametft capitalize">
                        {intermediate?.toLowerCase()}
                      </span>
                    </div>
                  </div>
                  <div className="TTtrainicon">
                    <div className="TTtraininnericon">
                      {/* <img src={leftline_tt} /> */}
                      <div
                        className={`left-line ${
                          mode === "trainFlightTrain" ? "w-[49px]" : "w-[69px]"
                        }`}
                      ></div>
                      {/* <img src={train} /> */}
                      <img
                        src={
                          mode === "trainTrain" || mode === "flightTrain"
                            ? train
                            : flight
                        }
                      />
                      {/* <img src={rightline_tt} /> */}
                      <div
                        className={`right-line ${
                          mode === "trainFlightTrain" ? "w-[49px]" : "w-[69px]"
                        }`}
                      ></div>
                    </div>
                    <div
                      className={`TTdaydatetft ${
                        mode === "trainFlightTrain"
                          ? "gap-[49px]"
                          : "gap-[88px]"
                      }`}
                    >
                      <div className="TTdaydateleft">
                        <div className="TTdaytft">
                          <span className="TTdaytexttft">
                            {formatDay(
                              mode === "trainFlightTrain"
                                ? (minDateData as TrainFlightTrainModel).flight
                                    .properties.departure_time
                                : mode === "trainTrain"
                                ? (minDateData as TrainTrainModel).train2
                                    .properties.departure_time
                                : mode === "flightTrain"
                                ? (minDateData as FlightTrainModel).train
                                    .properties.departure_time
                                : (minDateData as FlightTrainModel).flight
                                    .properties.departure_time
                            )}
                          </span>
                        </div>
                        <div className="datetft">
                          <span className="datetexttft">
                            {formatDateMonth(
                              mode === "trainFlightTrain"
                                ? (minDateData as TrainFlightTrainModel).flight
                                    .properties.departure_time
                                : mode === "trainTrain"
                                ? (minDateData as TrainTrainModel).train2
                                    .properties.departure_time
                                : mode === "flightTrain"
                                ? (minDateData as FlightTrainModel).train
                                    .properties.departure_time
                                : (minDateData as FlightTrainModel).flight
                                    .properties.departure_time
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="daydateright">
                        <div className="daytft">
                          <span className="daytexttft">
                            {formatDay(
                              mode === "trainFlightTrain"
                                ? (minDateData as TrainFlightTrainModel).flight
                                    .properties.arrival_time
                                : mode === "trainTrain"
                                ? (minDateData as TrainTrainModel).train2
                                    .properties.arrival_time
                                : mode === "flightTrain"
                                ? (minDateData as FlightTrainModel).train
                                    .properties.arrival_time
                                : (minDateData as FlightTrainModel).flight
                                    .properties.arrival_time
                            )}
                          </span>
                        </div>
                        <div className="datetft">
                          <span className="datetexttft">
                            {formatDateMonth(
                              mode === "trainFlightTrain"
                                ? (minDateData as TrainFlightTrainModel).flight
                                    .properties.arrival_time
                                : mode === "trainTrain"
                                ? (minDateData as TrainTrainModel).train2
                                    .properties.arrival_time
                                : mode === "flightTrain"
                                ? (minDateData as FlightTrainModel).train
                                    .properties.arrival_time
                                : (minDateData as FlightTrainModel).flight
                                    .properties.arrival_time
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {mode === "trainFlightTrain" && (
                <>
                  <div className="citynamemiddelcontainer">
                    <div className="innercitynamemiddel">
                      <span className="middlecitynametft capitalize">
                        {intermediate2?.toLowerCase()}
                      </span>
                    </div>
                  </div>
                  <div className="trainicon">
                    <div className="traininnericon">
                      {/* <img src={leftlinetft} /> */}
                      <div
                        className={`left-line ${
                          mode === "trainFlightTrain" ? "w-[49px]" : "w-[69px]"
                        }`}
                      ></div>
                      <img src={train} />
                      {/* <img src={rightlinetft} /> */}
                      <div
                        className={`right-line ${
                          mode === "trainFlightTrain" ? "w-[49px]" : "w-[69px]"
                        }`}
                      ></div>
                    </div>
                    <div
                      className={`daydatetft ${
                        mode === "trainFlightTrain"
                          ? "gap-[49px]"
                          : "gap-[88px]"
                      }`}
                    >
                      <div className="daydateleft">
                        <div className="daytft">
                          <span className="daytexttft">
                            {formatDay(
                              (minDateData as TrainFlightTrainModel).train2
                                .properties.departure_time
                            )}
                          </span>
                        </div>
                        <div className="datetft">
                          <span className="datetexttft">
                            {formatDateMonth(
                              (minDateData as TrainFlightTrainModel).train2
                                .properties.departure_time
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="daydateright">
                        <div className="daytft">
                          <span className="daytexttft">
                            {formatDay(
                              (minDateData as TrainFlightTrainModel).train2
                                .properties.arrival_time
                            )}
                          </span>
                        </div>
                        <div className="datetft">
                          <span className="datetexttft">
                            {formatDateMonth(
                              (minDateData as TrainFlightTrainModel).train2
                                .properties.arrival_time
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div
                className={`cityynameright ${
                  mode === "trainFlightTrain" ? "w-[75px]" : "flex-1"
                }`}
              >
                <span className="citynamerighttext capitalize">
                  {toCity?.toLowerCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="headerresult">
            <img src={result}></img>
            <span className="headerresulttext"> {length} {length === 1 ? "Result" : "Results"} </span>
          </div>
        </div>
      </div>
        );
      }
      {/* <div className="tfsearchcard w-full">
        <div className="header-vector">
          <div className="two-modes-cards-3">
            <span className="vijayawada-1 capitalize">
              {fromCity?.toLowerCase()}
            </span>
          </div>

          <div className="first-vector">
            <div className="line-1"></div>
            <div className="frame-2">
              {mode === "train" ||
              mode === "trainFlight" ||
              mode === "trainTrain" ||
              mode === "trainFlightTrain" ? (
                <img className="vector-35" src={Train} alt="" />
              ) : (
                <img className="vector-35" src={Plane} alt="" />
              )}
            </div>
            <div className="line-2"></div>
          </div>
          {mode !== "train" && mode !== "flight" && (
            <>
              <div className="two-modes-cards-4">
                <span className="hyderabad-1 capitalize">
                  {intermediate?.toLowerCase()}
                </span>
              </div>
              <div className="first-vector">
                <div className="line-1"></div>
                <div className="frame-2">
                  {mode === "trainFlight" || mode === "trainFlightTrain" ? (
                    <img className="vector-35" src={Plane} alt="plane icon" />
                  ) : (
                    <img className="vector-35" src={Train} alt="train icon" />
                  )}
                </div>
                <div className="line-2"></div>
              </div>
            </>
          )}
          {mode === "trainFlightTrain" ? (
            <>
              <div className="two-modes-cards-4">
                <span className="hyderabad-1 capitalize">
                  {intermediate2?.toLowerCase()}
                </span>
              </div>
              <div className="first-vector">
                <div className="line-1"></div>
                <div className="frame-2">
                  <img className="vector-35" src={Train} alt="train icon" />
                </div>
                <div className="line-2"></div>
              </div>
            </>
          ) : (
            <div></div>
          )}
          <div className="two-modes-cards-5">
            <span className="lucknow-1 capitalize">
              {toCity?.toLowerCase()}
            </span>
          </div>
        </div>
        <div className="frame-5131">
          <div className="frame-5121">
            <button
              className="arrow-drop-left-8"
              onClick={() => {
                CallDate(getPrevDay(departureDate));
              }}
            >
              <img className="vector-1016" src={Leftarrow} alt="leftarrow" />
            </button>
            <div className="frame-3101">
              <span className="may-20241">{departureDate}</span>
            </div>
            <button
              className="arrow-drop-right-8"
              onClick={() => {
                CallDate(getNextDay(departureDate));
              }}
            >
              <img className="vector-1017" src={Rightarrow} alt="rightarrow" />
            </button>
          </div>
        </div>
      </div> */
}
