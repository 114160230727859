import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./LandingPage.css";
// import "../components/loginpage.css";
import { setOpenlogin, setMapanimatestart } from "../store/dataSlice";
import Loginpalet from "../components/commons/Loginpalet";
import "@dotlottie/player-component";
import Searchbar from "../components/commons/Searchbar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { setMapProps } from "../store/dataSlice";

interface MapData {
  sourceLocation: {
    coordinates: [number, number];
    name: string;
  };
  destinationLocation: {
    coordinates: [number, number];
    name: string;
  };
  intermediate1Location: {
    coordinates: [number, number];
    name: string;
  };
  intermediate2Location: {
    coordinates: [number, number];
    name: string;
  };
  intermediateD1Location: {
    coordinates: [number, number];
    name: string;
  };
  intermediateD2Location: {
    coordinates: [number, number];
    name: string;
  };
  models: any; // Define specific type if possible
}

// Type for coordinate response
interface CoordinateResponse {
  sourceCity: {
    longitude: number;
    latitude: number;
    city_name: string;
  };
  destinationCity: {
    longitude: number;
    latitude: number;
    city_name: string;
  };
  TFTnearestStations: {
    src_nearest_a1: {
      longitude: number;
      latitude: number;
      city_name: string;
    };
    src_nearest_a3: {
      longitude: number;
      latitude: number;
      city_name: string;
    };
    dest_nearest_a1: {
      longitude: number;
      latitude: number;
      city_name: string;
    };
    dest_nearest_a3: {
      longitude: number;
      latitude: number;
      city_name: string;
    };
  };
  models: number[];
}

export default function LandingPage34() {
  const [fromCity, setFromCity] = useState("nanded");
  const [toCity, setToCity] = useState("mumbai");
  const [fromState, setFromState] = useState("maharashtra");
  const [toState, setToState] = useState("maharashtra");
  const [departureDate, setDepartureDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const openlogin = useSelector<RootState, boolean>(
    (state) => state.data.openlogin
  );
  const mapanimatestart = useSelector(
    (state: RootState) => state.data.mapanimatestart
  );

  const [adults, setAdults] = useState(1);
  const [loading, setLoading] = useState(false);

  //map usestates
  const [isNavHidden, setIsNavHidden] = useState<boolean>(false);
  const [isAnimationStarted, setIsAnimationStarted] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<CoordinateResponse | null>(
    null
  );

  const searchbarRef = useRef<HTMLDivElement>(null);

  const createMapData = (
    coordinates: CoordinateResponse | null
  ): MapData | null => {
    if (!coordinates) return null;

    return {
      sourceLocation: {
        coordinates: [
          coordinates.sourceCity?.longitude,
          coordinates.sourceCity?.latitude,
        ],
        name: coordinates.sourceCity?.city_name,
      },
      destinationLocation: {
        coordinates: [
          coordinates.destinationCity?.longitude,
          coordinates.destinationCity?.latitude,
        ],
        name: coordinates.destinationCity?.city_name,
      },
      intermediate1Location: {
        coordinates: [
          coordinates.TFTnearestStations?.src_nearest_a1?.longitude,
          coordinates.TFTnearestStations?.src_nearest_a1?.latitude,
        ],
        name: coordinates.TFTnearestStations?.src_nearest_a1?.city_name,
      },
      intermediate2Location: {
        coordinates: [
          coordinates.TFTnearestStations?.src_nearest_a3?.longitude,
          coordinates.TFTnearestStations?.src_nearest_a3?.latitude,
        ],
        name: coordinates.TFTnearestStations?.src_nearest_a3?.city_name,
      },
      intermediateD1Location: {
        coordinates: [
          coordinates.TFTnearestStations?.dest_nearest_a1?.longitude,
          coordinates.TFTnearestStations?.dest_nearest_a1?.latitude,
        ],
        name: coordinates.TFTnearestStations?.dest_nearest_a1?.city_name,
      },
      intermediateD2Location: {
        coordinates: [
          coordinates.TFTnearestStations?.dest_nearest_a3?.longitude,
          coordinates.TFTnearestStations?.dest_nearest_a3?.latitude,
        ],
        name: coordinates.TFTnearestStations?.dest_nearest_a3?.city_name,
      },
      models: coordinates.models,
    };
  };

  const handleGoClick = async (e?: React.MouseEvent<HTMLElement>) => {
    // Prevent default if event exists
    if (e) {
      e.preventDefault();
    }

    if (!fromCity || !toCity) {
      alert("Please enter both source and destination cities");
      return;
    }

    try {
      dispatch(setMapanimatestart(true));
      const response = await axios.post<CoordinateResponse>(
        `${process.env.REACT_APP_BACKEND_URL}/get-coordinates`,
        {
          source_city: fromCity,
          desti_city: toCity,
        }
      );

      if (response.data) {
        setCoordinates(response.data);
        const mapData = createMapData(response.data);

        if (mapData) {
          setIsNavHidden(true);
          setIsAnimationStarted(true);
          dispatch(setMapProps(mapData));

          searchbarRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        } else {
          alert("Invalid coordinate data received");
        }
      }
    } catch (err) {
      console.error("Error fetching coordinates:", err);
      alert("Error fetching coordinates. Please try again.");
      setIsAnimationStarted(false);
    }
  };

  const navigate = useNavigate();

  const isSearchDisabled = !fromCity || !toCity || !fromState || !toState;

  const handleSearch = async () => {
    try {
      sessionStorage.setItem("searched", "true");

      handleGoClick();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (mapanimatestart) {
      setTimeout(() => {
        navigate(
          `/modes?from=${fromCity?.toLowerCase()}&statefrom=${fromState?.toLowerCase()}&to=${toCity?.toLowerCase()}&stateto=${toState?.toLowerCase()}&date=${departureDate}&no=${adults}`
          // , {state: {},}
        );
      }, 1200);
    }
  }, [mapanimatestart]);
  
  const dispatch = useDispatch();

  return (
    <>
      {openlogin && (
        <div className="absolute h-screen w-screen  top-0 left-0 z-30 flex justify-center items-center">
          <button
            className="absolute h-screen w-screen  top-0 left-0 backdrop-blur-sm"
            onClick={() => {
              dispatch(setOpenlogin(false));
            }}
          ></button>
          <div className="logger z-30">
            <Loginpalet />
          </div>
        </div>
      )}

      <div className="z-10">
        <div className={`slogan ${isAnimationStarted ? "hidden" : ""}`}>
          <div className="slogan-1">Where to go? How to Go?</div>
          <div className="slogan-2">
            Just type in <span>Source & Destination</span>,
            <br />
            and get all your answers
          </div>
        </div>

        <div ref={searchbarRef}>
          <Searchbar
            handleClickFunction={handleSearch}
            fromCity={fromCity}
            toCity={toCity}
            fromState={fromState}
            toState={toState}
            setFromCity={setFromCity}
            setToCity={setToCity}
            setFromState={setFromState}
            setToState={setToState}
            departureDate={departureDate}
            setDepartureDate={setDepartureDate}
            adults={adults}
            setAdults={setAdults}
          />
        </div>
      </div>
    </>
  );
}
