import { useState } from "react";
import Google from "../../assets/images/google-svg.svg"
import Email from "../../assets/images/email-svg.svg"
import Apple from "../../assets/images/apple-svg.svg"
import Loginintroimg from "../../assets/images/login-intro-img.png"
import Closingcross from "../../assets/images/closing-cross.svg"
import { useSelector, useDispatch } from "react-redux";
import { setOpenlogin } from "../../store/dataSlice";

function Loginpalet() {

    const openlogin = useSelector((state) => state.data.openlogin);

    const dispatch = useDispatch();

    const [loginCred, setLoginCred] = useState("");


    const handleLogin = () => {
        if (loginCred === "") {
            alert("Please enter your email or mobile number");
            return;
        }
        else{
            dispatch(setOpenlogin(false));
        }

        // Proceed with login logic (e.g., API call) here
        // console.log("Login credential:", loginCred);
    };

    const handleLogin1 =() => {
        dispatch(setOpenlogin(false));
    }
    return (
        <div className="login-page">
            <div className="upper-box">
                <div className="wayport-title">WayPort</div>
                <div className="closing-cross" onClick={() => {
                            dispatch(setOpenlogin(false));
                        }}><img src={Closingcross} alt="" /></div>
            </div>
            <div className="login-box">
                <div className="liners-1">Get your Trip Sorted</div>
                <div className="liners-2">Just relax and let us help you plan your trip. </div>
                <div className="liners-3">
                    <img src={Loginintroimg} alt="" className="login-intro-img"/>
                </div>
                <div className="liners-4">
                <input 
                        type="text" 
                        className="login-cred" 
                        placeholder="Enter email or Mobile no." 
                        value={loginCred}
                        onChange={(e) => setLoginCred(e.target.value)}
                    />
                </div>
                <div className="liners-41" onClick={handleLogin}>
                        Login
                   
                </div>
                <div className="liners-5">
                or Login/Sign-up with
                </div>
                <div className="liners-6" onClick={handleLogin1}>
                    <img src={Google} alt="Google" className="login-icon" />
                    <span>Google</span>
                </div>
                <div className="liners-6" onClick={handleLogin1}>
                    <img src={Email} alt="Email" className="login-icon" />
                    <span>Email</span>
                </div>
                <div className="liners-6" onClick={handleLogin1}>
                    <img src={Apple} alt="Apple" className="login-icon" />
                    <span>Apple</span>
                </div>
                <div className="liners-9">By continuing you agree to our <span className="term-policy">Terms of Service</span> and <span className="term-policy">Privacy Policy.</span> </div>
            </div>
        </div>
    );
}

export default Loginpalet;
