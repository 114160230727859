import Indigo from "../../assets/flightnormal/indigo.png";
import AirIndia from "../../assets/flightnormal/airindia.png";
import AirIndiaExpress from "../../assets/flightnormal/airindiaexpress.png";
import Vistara from "../../assets/flightnormal/vistara.png";
import Spicejet from "../../assets/flightnormal/spicejet.png";
import AkasaAir from "../../assets/flightnormal/akasaair.png";
import Star_air from "../../assets/flightnormal/starair.png";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import Dotter from "../../assets/V2/dotterff.svg";

import Plane_V from "../../assets/V2/planes.svg";
import {
  DirectFlightModel,
  IndirectFlightModel,
} from "../../utils/models_types";
import dashline from "../../assets/vectors/dashline.svg";
function FlightFlightcard({
  ffCompenent,
}: {
  ffCompenent: IndirectFlightModel["properties"];
}) {
  const convertDurationToHours = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const dateFormat = new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  });

  const formatDate = (inputdate: number): string => {
    return dateFormat.format(new Date(inputdate - 5.5 * 60 * 60));
  };

  const timeFormat = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formatTime = (inputtime: number): string => {
    return timeFormat.format(new Date(inputtime));
  };

  const imageselector = (inputcomp: string): string | undefined => {
    if (inputcomp === "indigo") {
      return Indigo;
    } else if (inputcomp === "vistara") {
      return Vistara;
    } else if (inputcomp === "air india express") {
      return AirIndiaExpress;
    } else if (inputcomp === "air india") {
      return AirIndia;
    } else if (inputcomp === "akasa air") {
      return AkasaAir;
    } else if (inputcomp === "starair") {
      return Star_air;
    } else if (inputcomp === "spicejet") {
      return Spicejet;
    }
  };
  const adults = useSelector<RootState, number>((state) => state.data.adults);

  const flightname = (inputcomp: string): string | undefined => {
    if (inputcomp === "indigo") {
      return "6E";
    } else if (inputcomp === "vistara") {
      return "UK";
    } else if (inputcomp === "air india express") {
      return "IX";
    } else if (inputcomp === "air india") {
      return "AI";
    } else if (inputcomp === "akasa air") {
      return "QP";
    } else if (inputcomp === "starair") {
      return "S5";
    }
  };

  const n = ffCompenent.segments.length;

  return (
    <>
      <div className="flight-card-ff">
        <div className="ff-in">
          <div className="details">
            <div className="timings-container">
              <div className="date-time">
                <p className="date">
                  {formatDate(ffCompenent.segments[0].departure_time)}
                </p>
                <div className="time">
                  {formatTime(ffCompenent.segments[0].departure_time)}
                </div>
              </div>
              <div>
                <img src={dashline}></img>
              </div>
              <div className="date-time">
                <div className="time">
                  {formatTime(ffCompenent.segments[0].arrival_time)}
                </div>
                <p className="date">
                  {formatDate(ffCompenent.segments[0].arrival_time)}
                </p>
              </div>
            </div>
            <div className="train-icon-section-x">
              <div className="line flex flex-col items-center">
                <div className="h-[6px] w-[6px] bg-[#005073] rounded-full"></div>
                <div className="h-[24px] w-0 border-[1px] border-[#005073]"></div>
              </div>
              <div className="train-icon">
                <img src={Plane_V} style={{ width: 17, height: 17 }} />
              </div>
              <div className="line flex flex-col h-full items-center">
                <div>
                  <img src={Dotter} alt="" />
                </div>
              </div>
            </div>
            <div className="flight-details">
              <div className="airport-name-container">
                <p title={`${ffCompenent.segments[0].origin.name}`}>
                  {ffCompenent.segments[0].origin.name},{" "}
                  {ffCompenent.segments[0].origin.city}
                  {"terminal" in ffCompenent.segments[0].origin && (
                    <span> [T- {ffCompenent.segments[0].origin.terminal}]</span>
                  )}
                </p>
              </div>
              <div className="flight-mid">
                <div className="flight-type-container">
                  <div className="flight-image">
                    <img
                      style={{ objectFit: "contain" }}
                      src={imageselector(
                        ffCompenent.segments[0].flight_name?.toLowerCase()
                      )}
                    />
                  </div>
                  <p className="flight-no">
                    {flightname(ffCompenent.segments[0].flight_name)}{" "}
                    {ffCompenent.segments[0].flight_number}
                  </p>
                </div>
              </div>
              <div className="airport-name-container">
                <p title={`${ffCompenent.segments[0].destination.name}`}>
                  {ffCompenent.segments[0].destination.name},{" "}
                  {ffCompenent.segments[0].destination.city}
                  {"terminal" in ffCompenent.segments[0].destination && (
                    <span>
                      {" "}
                      [T- {ffCompenent.segments[0].destination.terminal}]
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="frame-1164">
            <div className="frame-1166">
              <div className="frame-1165">
                <div className="buffer-timing">
                  {convertDurationToHours(
                    (ffCompenent.segments[n - 1].arrival_time -
                      ffCompenent.segments[0].departure_time) /
                      60000
                  )}
                </div>
                <div className="red-dot"></div>
              </div>
              <div className="frame-1158">
                <div className="stop-name">
                  Connect in at {ffCompenent.segments[0].destination.city}
                </div>
              </div>
            </div>
            <div className="frame-11598">
              <div className="stops-types"> Indirect</div>
            </div>
          </div>
          <div className="details">
            <div className="timings-container">
              <div className="date-time">
                <p className="date">
                  {formatDate(ffCompenent.segments[n - 1].departure_time)}
                </p>
                <div className="time">
                  {formatTime(ffCompenent.segments[n - 1].departure_time)}
                </div>
              </div>
              <div>
                <img src={dashline}></img>
              </div>
              <div className="date-time">
                <div className="time">
                  {formatTime(ffCompenent.segments[n - 1].arrival_time)}
                </div>
                <p className="date">
                  {formatDate(ffCompenent.segments[n - 1].arrival_time)}
                </p>
              </div>
            </div>
            <div className="train-icon-section-x">
              <div className="line flex flex-col h-full items-center">
                <div>
                  <img src={Dotter} alt="" />
                </div>
              </div>
              <div className="train-icon">
                <img src={Plane_V} style={{ width: 17, height: 17 }} />
              </div>
              <div className="line flex flex-col items-center">
                <div className="h-[24px] border-[1px] border-[#005073]"></div>
                <div className="h-[6px] w-[6px] bg-[#005073] rounded-full"></div>
              </div>
            </div>
            <div className="flight-details">
              <div className="airport-name-container">
                <p title={`${ffCompenent.segments[n - 1].origin.name}`}>
                  {ffCompenent.segments[n - 1].origin.name},{" "}
                  {ffCompenent.segments[n - 1].origin.city}
                  {"terminal" in ffCompenent.segments[n - 1].origin && (
                    <span>
                      {" "}
                      [T- {ffCompenent.segments[n - 1].origin.terminal}]
                    </span>
                  )}
                </p>
              </div>
              <div className="flight-mid">
                <div className="flight-type-container">
                  <div className="flight-image">
                    <img
                      style={{ objectFit: "contain" }}
                      src={imageselector(
                        ffCompenent.segments[n - 1].flight_name?.toLowerCase()
                      )}
                    />
                  </div>
                  <p className="flight-no">
                    {flightname(ffCompenent.segments[n - 1].flight_name)}{" "}
                    {ffCompenent.segments[n - 1].flight_number}
                  </p>
                </div>
                {/* <p className="non-stop">Non-Stop</p> */}

                {/* {"stop_count" in ffCompenent &&
                            ffCompenent.stop_count === 0 ? (
                                <div className="flight-price-container">
                                    <p className="price">
                                        ₹{" "}
                                        {Math.round(ffCompenent.price / adults)}
                                    </p>
                                    <p className="per-individual">
                                        Per Individual
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )} */}
              </div>
              <div className="airport-name-container">
                <p title={`${ffCompenent.segments[n - 1].destination.name}`}>
                  {ffCompenent.segments[n - 1].destination.name},{" "}
                  {ffCompenent.segments[n - 1].destination.city}
                  {"terminal" in ffCompenent.segments[n - 1].destination && (
                    <span>
                      {" "}
                      [T- {ffCompenent.segments[n - 1].destination.terminal}]
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flight-price-container-ff">
          <p className="price">₹ {Math.round(ffCompenent.price / adults)}</p>
          <p className="per-individual">Per Individual</p>
        </div>
      </div>
    </>
  );
}

export default FlightFlightcard;
