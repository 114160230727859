import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "../components/commons/Navbar";
import LandingPage34 from "../pages/LandingPage";
import ModesPage from "../pages/ModesPage";
import TfcardsPage from "../pages/CardsPage";
import "../pages/LandingPage.css";
import MapComponent from "../components/Waymap";

export default function Router() {

  return (
    <div className="App relative">
      {/* {showMap && <ConditionalMap />} */}
      <BrowserRouter>
        <MapComponent>
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={<LandingPage34 />}
            />
            <Route path="/details" element={<TfcardsPage />} />
            <Route path="/modes" element={<ModesPage />} />
          </Routes>
        </MapComponent>
      </BrowserRouter>
    </div>
  );
}
