import {
  DirectFlightModel,
  IndirectFlightModel,
  TrainModel,
  FlightTrainModel,
  TrainTrainModel,
  TrainFlightTrainModel,
} from "./models_types";

const formatTime = (inputtime: string | number | Date) => {
  return timeFormat.format(new Date(inputtime));
};

const getHoursFromFormattedTime = (formattedTime: string) => {
  const [hours] = formattedTime.split(":").map(Number);
  return hours;
};

const timeFormat = new Intl.DateTimeFormat("en-GB", {
  hour: "2-digit",
  minute: "2-digit",
  timeZone: "UTC",
});

export function Sort({
  mode,
  modeData,
  range,
  dbefore6am,
  d6amto12pm,
  d12pmto6pm,
  d6pmto12am,
  abefore6am,
  a6amto12pm,
  a12pmto6pm,
  a6pmto12am,
  airlineFilters,
  direct,
  oneStop,
  twoPlusStops,
  cheapest,
  fastest,
}: {
  mode: string;
  modeData:
    | (DirectFlightModel | IndirectFlightModel)[]
    | TrainModel[]
    | FlightTrainModel[]
    | TrainTrainModel[]
    | TrainFlightTrainModel[];
  range: number[];
  dbefore6am: boolean;
  d6amto12pm: boolean;
  d12pmto6pm: boolean;
  d6pmto12am: boolean;
  abefore6am: boolean;
  a6amto12pm: boolean;
  a12pmto6pm: boolean;
  a6pmto12am: boolean;
  airlineFilters: {
    [key: string]: boolean;
  };
  direct: boolean;
  oneStop: boolean;
  twoPlusStops: boolean;
  cheapest: boolean;
  fastest: boolean;
}) {
  if (mode == "flight") {
    return ([...modeData] as (DirectFlightModel | IndirectFlightModel)[])
      .filter((flight) => {
        const totalPrice = flight.total_price ?? 0;

        // Price filter
        console.log(`Total Price: ${totalPrice}, Range: ${range}`);
        const priceFilter =
          range[0] === 0 || (range[0] <= totalPrice && totalPrice <= range[1]);
        console.log(`Price Filter Passed: ${priceFilter}`);

        const departureTime =
          "segments" in flight.properties
            ? flight.properties.segments[0].departure_time
            : new Date(flight.properties.departure_time);

        const arrivalTime =
          "segments" in flight.properties
            ? flight.properties.segments[flight.properties.segments.length - 1]
                .arrival_time
            : new Date(flight.properties.arrival_time);

        const formattedDepTime = formatTime(departureTime);
        const formattedArrTime = formatTime(arrivalTime);
        const depHours = getHoursFromFormattedTime(formattedDepTime);
        const arrHours = getHoursFromFormattedTime(formattedArrTime);

        const allDepFiltersFalse =
          !dbefore6am && !d6amto12pm && !d12pmto6pm && !d6pmto12am;
        const depFilter =
          allDepFiltersFalse ||
          (dbefore6am && depHours < 6) ||
          (d6amto12pm && depHours >= 6 && depHours < 12) ||
          (d12pmto6pm && depHours >= 12 && depHours < 18) ||
          (d6pmto12am && depHours >= 18);

        const allArrFiltersFalse =
          !abefore6am && !a6amto12pm && !a12pmto6pm && !a6pmto12am;
        const arrFilter =
          allArrFiltersFalse ||
          (abefore6am && arrHours < 6) ||
          (a6amto12pm && arrHours >= 6 && arrHours < 12) ||
          (a12pmto6pm && arrHours >= 12 && arrHours < 18) ||
          (a6pmto12am && arrHours >= 18);

        // Airline filters
        const selectedAirlines = Object.keys(airlineFilters).filter(
          (key) => airlineFilters[key]
        );

        const airlineFilterActive = selectedAirlines.length > 0;

        const airlineFilter =
          !airlineFilterActive ||
          ("segments" in flight.properties
            ? flight.properties.segments.some((segment) =>
                selectedAirlines.includes(
                  segment.flight_name?.toLowerCase().replaceAll(" ", "_")
                )
              )
            : selectedAirlines.includes(
                flight.properties.flight_name
                  ?.toLowerCase()
                  .replaceAll(" ", "_")
              ));

        // Stops filter
        const stops = flight.properties.stop_count || 0;
        const stopsFilter =
          (!direct && !oneStop && !twoPlusStops) ||
          (direct && stops === 0) ||
          (oneStop && stops === 1) ||
          (twoPlusStops && stops >= 2);

        return (
          depFilter && arrFilter && airlineFilter && stopsFilter && priceFilter
        );
      })
      .sort((a, b) => {
        if (cheapest) {
          return a.total_price ?? Infinity - (b.total_price ?? Infinity);
        } else if (fastest) {
          return a.total_duration ?? Infinity - (b.total_duration ?? Infinity);
        } else {
          return 0;
        }
      });
  } else if (mode == "train") {
    return ([...modeData] as TrainModel[])
      .filter((train) => {
        const totalPrice = train.total_price ?? 0;

        // Price filter
        const priceFilter =
          range[0] === 0 || (range[0] <= totalPrice && totalPrice <= range[1]);

        const departureTime = new Date(train.properties.departure_time);
        const arrivalTime = new Date(train.properties.arrival_time);
        const formattedDepTime = formatTime(departureTime);
        const formattedArrTime = formatTime(arrivalTime);
        const depHours = getHoursFromFormattedTime(formattedDepTime);
        const arrHours = getHoursFromFormattedTime(formattedArrTime);

        const allDepFiltersFalse =
          !dbefore6am && !d6amto12pm && !d12pmto6pm && !d6pmto12am;
        const depFilter =
          allDepFiltersFalse ||
          (dbefore6am && depHours < 6) ||
          (d6amto12pm && depHours >= 6 && depHours < 12) ||
          (d12pmto6pm && depHours >= 12 && depHours < 18) ||
          (d6pmto12am && depHours >= 18);

        const allArrFiltersFalse =
          !abefore6am && !a6amto12pm && !a12pmto6pm && !a6pmto12am;
        const arrFilter =
          allArrFiltersFalse ||
          (abefore6am && arrHours < 6) ||
          (a6amto12pm && arrHours >= 6 && arrHours < 12) ||
          (a12pmto6pm && arrHours >= 12 && arrHours < 18) ||
          (a6pmto12am && arrHours >= 18);

        return depFilter && arrFilter && priceFilter;
      })
      .sort((a, b) => {
        if (cheapest) {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        } else if (fastest) {
          return (
            (a.total_duration ?? Infinity) - (b.total_duration ?? Infinity)
          );
        } else {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        }
      });
  } else if (mode == "trainTrain") {
    return ([...modeData] as TrainTrainModel[])
      .filter((trainTrain) => {
        const totalPrice = trainTrain.total_price ?? 0;

        // Price filter
        const priceFilter =
          range[0] === 0 || (range[0] <= totalPrice && totalPrice <= range[1]);

        const departureTime = new Date(
          trainTrain.train1.properties.departure_time
        );
        const arrivalTime = new Date(trainTrain.train2.properties.arrival_time);
        const formattedDepTime = formatTime(departureTime);
        const formattedArrTime = formatTime(arrivalTime);
        const depHours = getHoursFromFormattedTime(formattedDepTime);
        const arrHours = getHoursFromFormattedTime(formattedArrTime);

        const allDepFiltersFalse =
          !dbefore6am && !d6amto12pm && !d12pmto6pm && !d6pmto12am;
        const depFilter =
          allDepFiltersFalse ||
          (dbefore6am && depHours < 6) ||
          (d6amto12pm && depHours >= 6 && depHours < 12) ||
          (d12pmto6pm && depHours >= 12 && depHours < 18) ||
          (d6pmto12am && depHours >= 18);

        const allArrFiltersFalse =
          !abefore6am && !a6amto12pm && !a12pmto6pm && !a6pmto12am;
        const arrFilter =
          allArrFiltersFalse ||
          (abefore6am && arrHours < 6) ||
          (a6amto12pm && arrHours >= 6 && arrHours < 12) ||
          (a12pmto6pm && arrHours >= 12 && arrHours < 18) ||
          (a6pmto12am && arrHours >= 18);

        // // Airline filters
        // const selectedAirlines = Object.keys(
        //   airlineFilters
        // ).filter((key) => airlineFilters[key]);
        // const airlineFilterActive = selectedAirlines.length > 0;
        // const airlineFilter =
        //   !airlineFilterActive ||
        //   selectedAirlines.includes(
        //     trainFlight.flight.properties.flight_name
        //       .toLowerCase()
        //       .replace(" ", "_")
        //   );

        return depFilter && arrFilter && priceFilter;
      })
      .sort((a, b) => {
        if (cheapest) {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        } else if (fastest) {
          return (
            (a.total_duration ?? Infinity) - (b.total_duration ?? Infinity)
          );
        } else {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        }
      });
  } else if (mode == "trainFlight") {
    return ([...modeData] as FlightTrainModel[])
      .filter((trainFlight) => {
        const totalPrice = trainFlight.total_price ?? 0;

        // Price filter
        const priceFilter =
          range[0] === 0 || (range[0] <= totalPrice && totalPrice <= range[1]);

        const departureTime = new Date(
          trainFlight.train.properties.departure_time
        );
        const arrivalTime = new Date(
          trainFlight.flight.properties.arrival_time
        );
        const formattedDepTime = formatTime(departureTime);
        const formattedArrTime = formatTime(arrivalTime);
        const depHours = getHoursFromFormattedTime(formattedDepTime);
        const arrHours = getHoursFromFormattedTime(formattedArrTime);

        const allDepFiltersFalse =
          !dbefore6am && !d6amto12pm && !d12pmto6pm && !d6pmto12am;
        const depFilter =
          allDepFiltersFalse ||
          (dbefore6am && depHours < 6) ||
          (d6amto12pm && depHours >= 6 && depHours < 12) ||
          (d12pmto6pm && depHours >= 12 && depHours < 18) ||
          (d6pmto12am && depHours >= 18);

        const allArrFiltersFalse =
          !abefore6am && !a6amto12pm && !a12pmto6pm && !a6pmto12am;
        const arrFilter =
          allArrFiltersFalse ||
          (abefore6am && arrHours < 6) ||
          (a6amto12pm && arrHours >= 6 && arrHours < 12) ||
          (a12pmto6pm && arrHours >= 12 && arrHours < 18) ||
          (a6pmto12am && arrHours >= 18);

        // Airline filters
        const selectedAirlines = Object.keys(airlineFilters).filter(
          (key) => airlineFilters[key]
        );
        const airlineFilterActive = selectedAirlines.length > 0;
        const airlineFilter =
          !airlineFilterActive ||
          selectedAirlines.includes(
            trainFlight.flight.properties.flight_name
              ?.toLowerCase()
              .replaceAll(" ", "_")
          );

        return depFilter && arrFilter && airlineFilter && priceFilter;
      })
      .sort((a, b) => {
        if (cheapest) {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        } else if (fastest) {
          return (
            (a.total_duration ?? Infinity) - (b.total_duration ?? Infinity)
          );
        } else {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        }
      });
  } else if (mode == "flightTrain") {
    return ([...modeData] as FlightTrainModel[])
      .filter((flightTrain) => {
        const totalPrice = flightTrain.total_price ?? 0;

        // Price filter
        const priceFilter =
          range[0] === 0 || (range[0] <= totalPrice && totalPrice <= range[1]);

        const departureTime = new Date(
          flightTrain.flight.properties.departure_time
        );
        const arrivalTime = new Date(flightTrain.train.properties.arrival_time);
        const formattedDepTime = formatTime(departureTime);
        const formattedArrTime = formatTime(arrivalTime);
        const depHours = getHoursFromFormattedTime(formattedDepTime);
        const arrHours = getHoursFromFormattedTime(formattedArrTime);

        const allDepFiltersFalse =
          !dbefore6am && !d6amto12pm && !d12pmto6pm && !d6pmto12am;
        const depFilter =
          allDepFiltersFalse ||
          (dbefore6am && depHours < 6) ||
          (d6amto12pm && depHours >= 6 && depHours < 12) ||
          (d12pmto6pm && depHours >= 12 && depHours < 18) ||
          (d6pmto12am && depHours >= 18);

        const allArrFiltersFalse =
          !abefore6am && !a6amto12pm && !a12pmto6pm && !a6pmto12am;
        const arrFilter =
          allArrFiltersFalse ||
          (abefore6am && arrHours < 6) ||
          (a6amto12pm && arrHours >= 6 && arrHours < 12) ||
          (a12pmto6pm && arrHours >= 12 && arrHours < 18) ||
          (a6pmto12am && arrHours >= 18);

        // Airline filters
        const selectedAirlines = Object.keys(airlineFilters).filter(
          (key) => airlineFilters[key]
        );
        const airlineFilterActive = selectedAirlines.length > 0;
        const airlineFilter =
          !airlineFilterActive ||
          selectedAirlines.includes(
            flightTrain.flight.properties.flight_name
              ?.toLowerCase()
              .replaceAll(" ", "_")
          );

        return depFilter && arrFilter && airlineFilter && priceFilter;
      })
      .sort((a, b) => {
        if (cheapest) {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        } else if (fastest) {
          return (
            (a.total_duration ?? Infinity) - (b.total_duration ?? Infinity)
          );
        } else {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        }
      });
  } else if (mode === "trainFlightTrain") {
    return ([...modeData] as TrainFlightTrainModel[])
      .filter((trainFlightTrain) => {
        const totalPrice = trainFlightTrain.total_price ?? 0;

        // Price filter
        const priceFilter =
          range[0] === 0 || (range[0] <= totalPrice && totalPrice <= range[1]);

        const departureTime = new Date(
          trainFlightTrain.train1.properties.departure_time
        );
        const arrivalTime = new Date(
          trainFlightTrain.train2.properties.arrival_time
        );
        const formattedDepTime = formatTime(departureTime);
        const formattedArrTime = formatTime(arrivalTime);
        const depHours = getHoursFromFormattedTime(formattedDepTime);
        const arrHours = getHoursFromFormattedTime(formattedArrTime);

        const allDepFiltersFalse =
          !dbefore6am && !d6amto12pm && !d12pmto6pm && !d6pmto12am;
        const depFilter =
          allDepFiltersFalse ||
          (dbefore6am && depHours < 6) ||
          (d6amto12pm && depHours >= 6 && depHours < 12) ||
          (d12pmto6pm && depHours >= 12 && depHours < 18) ||
          (d6pmto12am && depHours >= 18);

        const allArrFiltersFalse =
          !abefore6am && !a6amto12pm && !a12pmto6pm && !a6pmto12am;
        const arrFilter =
          allArrFiltersFalse ||
          (abefore6am && arrHours < 6) ||
          (a6amto12pm && arrHours >= 6 && arrHours < 12) ||
          (a12pmto6pm && arrHours >= 12 && arrHours < 18) ||
          (a6pmto12am && arrHours >= 18);

        // Airline filters
        const selectedAirlines = Object.keys(airlineFilters).filter(
          (key) => airlineFilters[key]
        );
        const airlineFilterActive = selectedAirlines.length > 0;
        const airlineFilter =
          !airlineFilterActive ||
          selectedAirlines.includes(
            trainFlightTrain.flight.properties.flight_name
              ?.toLowerCase()
              .replaceAll(" ", "_")
          );

        return depFilter && arrFilter && airlineFilter && priceFilter;
      })
      .sort((a, b) => {
        if (cheapest) {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        } else if (fastest) {
          return (
            (a.total_duration ?? Infinity) - (b.total_duration ?? Infinity)
          );
        } else {
          return (a.total_price ?? Infinity) - (b.total_price ?? Infinity);
        }
      });
  }
}
