import Indigo from "../../assets/flightnormal/indigo.png";
import AirIndia from "../../assets/flightnormal/airindia.png";
import AirIndiaExpress from "../../assets/flightnormal/airindiaexpress.png";
import Vistara from "../../assets/flightnormal/vistara.png";
import StarAir from "../../assets/flightnormal/starair.png";
import Spicejet from "../../assets/flightnormal/spicejet.png";
import AkasaAir from "../../assets/flightnormal/akasaair.png";

import Plane_V from "../../assets/V2/planes.svg";
import { useSelector } from "react-redux";
import { DirectFlightModel, FlightSegment } from "../../utils/models_types";
import { RootState } from "../../store/store";
import dashline from "../../assets/vectors/dashline.svg";
import cardPlane from "../../assets/vectors/cardplane.svg";
function FlightIndividual({
  flightComponent,
}: {
  flightComponent: DirectFlightModel["properties"] | FlightSegment;
}) {
  const convertDurationToHours = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const dateFormat = new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  });

  const formatDate = (inputdate: number): string => {
    return dateFormat.format(new Date(inputdate - 5.5 * 60 * 60));
  };

  const timeFormat = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formatTime = (inputtime: number): string => {
    return timeFormat.format(new Date(inputtime));
  };

  const imageselector = (inputcomp: string): string | undefined => {
    if (inputcomp === "indigo") {
      return Indigo;
    } else if (inputcomp === "vistara") {
      return Vistara;
    } else if (inputcomp === "air india express") {
      return AirIndiaExpress;
    } else if (inputcomp === "air india") {
      return AirIndia;
    } else if (inputcomp === "akasa air") {
      return AkasaAir;
    } else if (inputcomp === "starair") {
      return StarAir;
    } else if (inputcomp === "spicejet") {
      return Spicejet;
    }
  };

  const flightname = (inputcomp: string): string | undefined => {
    if (inputcomp === "IndiGo") {
      return "6E";
    } else if (inputcomp === "Vistara") {
      return "UK";
    } else if (inputcomp === "Air India Express") {
      return "IX";
    } else if (inputcomp === "Air India") {
      return "AI";
    } else if (inputcomp === "Akasa Air") {
      return "QP";
    } else if (inputcomp === "StarAir") {
      return "S5";
    }
  };

  const adults = useSelector<RootState, number>((state) => state.data.adults);

  return (
    <div className="flight-card">
      <div className="details">
        <div className="timings-container">
          <div className="date-time">
            <p className="date">{formatDate(flightComponent.departure_time)}</p>
            <div className="time">
              {formatTime(flightComponent.departure_time)}
            </div>
          </div>
          {/* <div className="flex-1 border-[1.5px] border-dashed border-black"></div> */}
          <div>
            <img src={dashline}></img>
          </div>
          <div className="duration-text">
            {convertDurationToHours(flightComponent.duration)}
          </div>
          {/* <div className="flex-1 border-[1.5px] border-dashed border-black"></div> */}
          <div>
            <img src={dashline} className="rotate-180" alt="Rotated image" />
          </div>

          <div className="date-time">
            <div className="time">
              {formatTime(flightComponent.arrival_time)}
            </div>
            <p className="date">{formatDate(flightComponent.arrival_time)}</p>
          </div>
        </div>
        <div className="train-icon-section flex items-center">
          {/* Top line with dot at the end */}
          <div className="cardiconline line flex flex-col items-center justify-between ">
            <div className="h-[6px] w-[6px] bg-[#005073] rounded-full"></div>
            <div className="flex-grow w-0 border-[1px] border-[#005073]"></div>
          </div>

          {/* Train icon */}
          <div className="train-icon">
            <img src={Plane_V} style={{width:17 , height:17}}/>
          </div>

          {/* Bottom line with dot at the start */}
          <div className="cardiconline line flex flex-col items-center justify-between ">
            <div className="flex-grow border-[1px] border-[#005073]"></div>
            <div className="h-[6px] w-[6px] bg-[#005073] rounded-full"></div>
          </div>
        </div>
        <div className="flight-details">
          <div className="airport-name-container">
            <p title={`${flightComponent.origin.name}`}>
              {flightComponent.origin.name}, {flightComponent.origin.city}
              {"terminal" in flightComponent.origin && (
                <span> [T- {flightComponent.origin.terminal}]</span>
              )}
            </p>
          </div>
          <div className="flight-mid">
            <div className="flight-type-container">
              <div className="flight-image">
                <img
                  style={{ objectFit: "contain" }}
                  src={imageselector(
                    flightComponent.flight_name?.toLowerCase()
                  )}
                />
              </div>
              <p className="flight-no">
                {flightname(flightComponent.flight_name)}{" "}
                {flightComponent.flight_number}
              </p>
            </div>
            <p className="non-stop">Non-Stop</p>

            {"stop_count" in flightComponent &&
            flightComponent.stop_count === 0 ? (
              <div className="flight-price-container">
                <p className="price">
                  ₹ {Math.round(flightComponent.price / adults)}
                </p>
                <p className="per-individual">Per Individual</p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="airport-name-container">
            <p title={`${flightComponent.destination.name}`}>
              {flightComponent.destination.name},{" "}
              {flightComponent.destination.city}
              {"terminal" in flightComponent.destination && (
                <span> [T- {flightComponent.destination.terminal}]</span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightIndividual;
