import "../../styles/navbar.css";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setOpenlogin } from "../../store/dataSlice";

export default function Navbar() {
  const dispatch = useDispatch();
  const handleLogin = () => {
    // console.log("clicked.........................");
    dispatch(setOpenlogin(true));
  };

  // const navigate = useNavigate();

  return (
    <div className="navbar z-30">
      <Link to={"/"}>
        <div className="logo">
            WayPort
          
        </div>
      </Link>
      <div className="navbar-opts">
        <span className="login" onClick={handleLogin}>
          Login
        </span>
        <span className="about-us">About Us</span>
        <span className="support">Support</span>
      </div>
    </div>
  );
}