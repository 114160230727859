import { useState } from "react";
import { useSelector } from "react-redux";

import Infocircle from "../../assets/vectors/BxInfoCircle_x2.svg";
import People from "../../assets/vectors/Vector75_x2.svg";
import Buffer from "../../assets/vectors/BxInfoCircle9_x2.svg";

import TrainIndividual from "../cards/traincard";
import FlightIndividual from "../cards/flightcard";
import { RootState } from "../../store/store";
import { TrainFlightTrainModel } from "../../utils/models_types";
import info from "../../assets/vectors/bx-info-circle.svg";
import bufferline from "../../assets/vectors/bufferline.svg";
import greeninfo from "../../assets/vectors/greencircle.svg";

export default function TrainFlightTrainCard({
  trainFlightTrain,
  fromCity,
  toCity,
  getFares,
}: {
  trainFlightTrain: TrainFlightTrainModel;
  fromCity: string;
  toCity: string;
  getFares: (
    train_number: string,
    fromStation: string,
    toStation: string,
    classTypes: string[],
    quota: string
  ) => Promise<void>;
}) {
  const [selectedPrice1, setSelectedPrice1] = useState<number>();
  // getAvailableClass(trainFlightTrain.train1.properties.prices.general)

  const [selectedPrice2, setSelectedPrice2] = useState<number>();
  // getAvailableClass(trainFlightTrain.train2.properties.prices.general)
  const adults = useSelector<RootState, number>((state) => state.data.adults);
  const convertDurationToHours = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  return (
    <>
      <div className="maincard-wrapper">
        <div className="cardsplace">
          <TrainIndividual
            trainComponent={trainFlightTrain.train1}
            fromCity={fromCity}
            toCity={trainFlightTrain.intermediate1}
            getFares={getFares}
            selectedPrice={selectedPrice1}
            setSelectedPrice={setSelectedPrice1}
          />

          <div className="bufferwraper">
            <div className="hourandnmin">
              <div className="hourminframeline">
                <img src={bufferline}></img>
              </div>

              <div className="hourminframe">
                <span className="hourandmintext">
                  {convertDurationToHours(
                    Math.abs(
                      (new Date(
                        trainFlightTrain.flight.properties.departure_time
                      ).getTime() -
                        new Date(
                          trainFlightTrain.train1.properties.arrival_time
                        ).getTime()) /
                        (1000 * 60)
                    )
                  )}
                </span>
              </div>
              <div>
                <img src={bufferline}></img>
              </div>
            </div>
            <div className="buffericonandtext">
              <img src={greeninfo} className="iconshrik"></img>
              <div className="bufferstop">
                <span className="bufferstoptext">Buffer</span>
              </div>
            </div>
          </div>

          <FlightIndividual
            flightComponent={trainFlightTrain.flight.properties}
          />

          <div className="bufferwraper">
            <div className="hourandnmin">
              <div className="hourminframeline">
                <img src={bufferline}></img>
              </div>

              <div className="hourminframe">
                <span className="hourandmintext">
                  {convertDurationToHours(
                    Math.abs(
                      (new Date(
                        trainFlightTrain.train2.properties.departure_time
                      ).getTime() -
                        new Date(
                          trainFlightTrain.flight.properties.arrival_time
                        ).getTime()) /
                        (1000 * 60)
                    )
                  )}
                </span>
              </div>
              <div>
                <img src={bufferline}></img>
              </div>
            </div>
            <div className="buffericonandtext">
              <img src={greeninfo} className="iconshrik"></img>
              <div className="bufferstop">
                <span className="bufferstoptext">Buffer</span>
              </div>
            </div>
          </div>

          <TrainIndividual
            trainComponent={trainFlightTrain.train2}
            fromCity={trainFlightTrain.intermediate2}
            toCity={toCity}
            getFares={getFares}
            selectedPrice={selectedPrice2}
            setSelectedPrice={setSelectedPrice2}
          />
        </div>
        <div className="priceplace">
          <div className="journeutour">
            <span className="journeutourtext">
              Journey Duration :{" "}
              <span className="journeytime">
                {convertDurationToHours(trainFlightTrain.total_duration ?? 0)}
              </span>
            </span>
          </div>
          <div className="totalprice">
            <div className="totalt-price">
              <span className="totalpricetext">
                Total Price :{" "}
                <span className="money">
                  {trainFlightTrain.flight.properties.price &&
                  selectedPrice1 &&
                  selectedPrice2
                    ? trainFlightTrain.flight.properties.price +
                      selectedPrice1 * adults +
                      selectedPrice2 * adults
                    : "Select Train Class "}
                </span>
              </span>
            </div>
            <div className="infoicon">
              <img src={info}></img>
            </div>
          </div>
          <button
            className="book-button-tt-tf-ft"
            disabled={!selectedPrice1 || !selectedPrice2}
          >
            <span className="buttonbook">Select</span>
          </button>
        </div>
      </div>

      {/* 
            <div className="card-wrapper">
                <div className="wrapper-top-1">
                    <TrainIndividual
                        trainComponent={trainFlightTrain.train1}
                        fromCity={fromCity}
                        toCity={trainFlightTrain.intermediate1}
                        getFares={getFares}
                        selectedPrice={selectedPrice1}
                        setSelectedPrice={setSelectedPrice1}
                    />
                </div>
                <div className="wrapper-top-0">
                    <div className="frame-5532-x">
                        <div className="frame-2378">
                            <div className="vector-1816"></div>
                        </div>
                        <div className="frame-2512">
                            <span className="h-5">
                                {convertDurationToHours(
                                    Math.abs(
                                        (new Date(
                                            trainFlightTrain.flight.properties.departure_time
                                        ).getTime() -
                                            new Date(
                                                trainFlightTrain.train1.properties.arrival_time
                                            ).getTime()) /
                                            (1000 * 60)
                                    )
                                )}
                            </span>
                        </div>
                        <div className="frame-2388">
                            <div className="vector-1817"></div>
                        </div>
                    </div>
                    <div className="buffer-2-x">
                        <img
                            className="bx-info-circle-6"
                            src={Buffer}
                            alt="buffer icon"
                        />
                        <div className="stops-5 font-primary">
                            <span className="non-stop-2">Buffer</span>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper-top"
                    style={{ paddingBottom: 2, paddingTop: 0 }}
                >
                    <FlightIndividual
                        flightComponent={trainFlightTrain.flight.properties}
                    />
                </div>
                <div className="wrapper-top-0">
                    <div className="frame-5532-x">
                        <div className="frame-2378">
                            <div className="vector-1816"></div>
                        </div>
                        <div className="frame-2512">
                            <span className="h-5">
                                {convertDurationToHours(
                                    Math.abs(
                                        (new Date(
                                            trainFlightTrain.train2.properties.departure_time
                                        ).getTime() -
                                            new Date(
                                                trainFlightTrain.flight.properties.arrival_time
                                            ).getTime()) /
                                            (1000 * 60)
                                    )
                                )}
                            </span>
                        </div>
                        <div className="frame-2388">
                            <div className="vector-1817"></div>
                        </div>
                    </div>
                    <div className="buffer-2-x">
                        <img
                            className="bx-info-circle-6"
                            src={Buffer}
                            alt="buffer icon"
                        />
                        <div className="stops-5 font-primary">
                            <span className="non-stop-2">Buffer</span>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper-top-1"
                    style={{ paddingBottom: 12, paddingTop: 2 }}
                >
                    <TrainIndividual
                        trainComponent={trainFlightTrain.train2}
                        fromCity={trainFlightTrain.intermediate2}
                        toCity={toCity}
                        getFares={getFares}
                        selectedPrice={selectedPrice2}
                        setSelectedPrice={setSelectedPrice2}
                    />
                </div>
                <div className="wrapper-bottom">
                    <div className="wrapper-duration">
                        <span className="journey-duration-11-h">
                            Journey Duration :{" "}
                            {convertDurationToHours(
                                trainFlightTrain.total_duration ?? 0
                            )}
                        </span>
                    </div>
                    <div className="wrapper-price">
                        <div className="wrapper-price-text">
                            <p className="total-price-15972">
                                <span className="total-price-15972-sub-14">
                                    Total Price :{" "}
                                </span>
                                <span>
                                    {trainFlightTrain.flight.properties.price &&
                                    selectedPrice1 &&
                                    selectedPrice2
                                        ? trainFlightTrain.flight.properties
                                              .price +
                                          selectedPrice1 * adults +
                                          selectedPrice2 * adults
                                        : "Select Train Class"}
                                </span>
                            </p>
                        </div>
                        <div className="wrapper-adults">
                            <img
                                className="bx-info-circle"
                                src={Infocircle}
                                alt="info icon"
                            />
                            <div className="adults-count">
                                <div className="adults-count-no">
                                    <span className="container-tra">
                                        {adults}
                                    </span>
                                </div>
                                <img
                                    className="vector-tra"
                                    src={People}
                                    alt="people icon"
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        disabled={!selectedPrice1 || !selectedPrice2}
                        className="book-btn disabled:saturate-0 disabled:cursor-not-allowed"
                    >
                        <span className="book">Book</span>
                    </button>
                </div>
            </div> */}
    </>
  );
}
