import { useState, useCallback } from "react";
import { searchDocuments } from "../../utils/elastic_recommender";
import DatePicker from "react-multi-date-picker";
import { formatDay } from "../../utils/misc";
import { Train } from "../../utils/models_types";

import "../../styles/searchbar.css";
import Location from "../../assets/V2/location.svg";
import calender from "../../assets/V2/calender.svg";
import traveller from "../../assets/V2/traveller.svg";

interface SearchbarProps {
    handleClickFunction: (e?: React.MouseEvent<HTMLElement>) => Promise<void>;
    fromCity: string;
    toCity: string;
    fromState : string;
    toState : string;
    setFromCity: Function;
    setToCity: Function;
    setFromState: Function;
    setToState: Function;
    departureDate: string;
    setDepartureDate: Function;
    adults: number;
    setAdults: Function;
}

const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export default function Searchbar({
    handleClickFunction,
    fromCity,
    toCity,
    fromState,
    toState,
    setFromCity,
    setToCity,
    setFromState,
    setToState,
    departureDate,
    setDepartureDate,
    adults, 
    setAdults
}: SearchbarProps) {
   
    const [fromCitySuggestions, setFromCitySuggestions] = useState<Train[]>([]);
    const [toCitySuggestions, setToCitySuggestions] = useState<Train[]>([]);
    const [adultsOpen, setAdultsOpen] = useState(false);
    
    const clearInput = (type: string) => {
        if (type === "from") {
            setFromCity("");
            setFromState("");
            setFromCitySuggestions([]);
        } else {
            setToCity("");
            setToState("");
            setToCitySuggestions([]);
        }
    };

    const handleFromCityChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const query = e.target.value;
        setFromCity(query);
        if (query.length > 0) {
            debouncedFetchFromCitySuggestions(query);
            // await fetchCitySuggestions(query, setFromCitySuggestions);
        } else {
            setFromCitySuggestions([]);
        }
    };

    const handleToCityChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const query = e.target.value;
        setToCity(query);
        if (query.length > 0) {
            debouncedFetchToCitySuggestions(query);
            // await fetchCitySuggestions(query, setToCitySuggestions);
        } else {
            setToCitySuggestions([]);
        }
    };

    const handleDateChange = (e: any) => {
        const formatDate = new Date(e).toISOString().split("T")[0];
        setDepartureDate(formatDate);
    };

    //Fetch City Suggestion
    const fetchCitySuggestions = async (
        query: string,
        setSuggestions: Function
    ) => {
        if (!query) {
            setSuggestions([]);
            return;
        }

        const results = await searchDocuments(query);
        setSuggestions(results?.hits);
    };

    const selectCity = (city: Train, type: string) => {
        if (type === "from") {
            setFromCity(city.city_name);
            setFromState(city.state_name);
            setFromCitySuggestions([]);
        } else {
            setToCity(city.city_name);
            setToState(city.state_name);
            setToCitySuggestions([]);
        }
    };
    
    // Debounced versions of fetchCitySuggestions
    const debouncedFetchFromCitySuggestions = useCallback(
        debounce(
            (query: string) =>
                fetchCitySuggestions(query, setFromCitySuggestions),
            500
        ),
        []
    );
    const debouncedFetchToCitySuggestions = useCallback(
        debounce(
            (query: string) =>
                fetchCitySuggestions(query, setToCitySuggestions),
            500
        ),
        []
    );

    const isSearchDisabled = !fromCity || !toCity || !fromState || !toState;

    // const handleSearchClick = async (e: React.MouseEvent<HTMLElement>) => {
    //     // Await the function call separately
    //     e.preventDefault();
    //     await handleClickFunction(e);

    //     // Dispatch actions with valid city data
    //     if (fromCity) {
    //         dispatch(setFromCity(fromCity));
    //     }
    //     if (toCity) {
    //         dispatch(setToCity(toCity));
    //     }
    //     dispatch(setMapanimatestart(true));
    // };

    return (
        <div className="searchbar">
            <div className="searchselection">
                <div className="text">
                    <span className="from">From</span>
                </div>
                <div className="input">
                    <div className="icon">
                        <img src={Location} alt="Location icon" />
                    </div>
                    <div className="inputdetails">
                        <div className="cityname">
                            <input
                                className="textcityname capitalize"
                                value={fromCity?.toLowerCase()}
                                onChange={handleFromCityChange}
                                type="text"
                                placeholder="Departure City"
                            />
                            {fromCity && (
                                <span
                                    className="clear-cross-1"
                                    onClick={() => clearInput("from")}
                                >
                                    ×
                                </span>
                            )}
                        </div>
                        {fromState && (
                            <div className="statename">
                                {fromCity && (
                                    <span
                                        className="textstatename capitalize"
                                        title={`${fromState}, India`}
                                    >
                                        {fromState?.toLowerCase()}, India
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {fromCitySuggestions.length > 0 && (
                    <div className="suggestions">
                        <div className="top-search-1">Top searches</div>
                        {fromCitySuggestions.map((city, index) => (
                            <div
                                key={index}
                                className="suggestion capitalize"
                                onClick={() => selectCity(city, "from")}
                            >
                                {city.city_name?.toLowerCase()}
                                <div className="state-display">
                                    {city.state_name?.toLowerCase()}, India
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="seperation">
                <div className="innerseperation"></div>
            </div>
            <div className="searchselection">
                <div className="text">
                    <span className="from">To</span>
                </div>
                <div className="input">
                    <div className="icon">
                        <img src={Location} alt="Location icon" />
                    </div>
                    <div className="inputdetails">
                        <div className="cityname">
                            <input
                                className="textcityname capitalize"
                                value={toCity?.toLowerCase()}
                                onChange={handleToCityChange}
                                type="text"
                                placeholder="Destination City"
                            />
                            {toCity && (
                                <span
                                    className="clear-cross-1"
                                    onClick={() => clearInput("to")}
                                >
                                    ×
                                </span>
                            )}
                        </div>
                        {toState && (
                            <div className="statename">
                                {toCity && (
                                    <span
                                        className="textstatename capitalize"
                                        title={`${toState}, India`}
                                    >
                                        {toState?.toLowerCase()}, India
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {toCitySuggestions.length > 0 && (
                    <div className="suggestions">
                        <div className="top-search-1">Top searches</div>
                        {toCitySuggestions.map((city, index) => (
                            <div
                                key={index}
                                className="suggestion capitalize"
                                onClick={() => selectCity(city, "to")}
                            >
                                {city.city_name?.toLowerCase()}
                                <div className="state-display">
                                    {city.state_name?.toLowerCase()}, India
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="seperation">
                <div className="innerseperation"></div>
            </div>
            <div className="searchselection">
                <div className="text">
                    <span className="from">Departure</span>
                </div>
                <div className="input-x">
                    <div className="icon" style={{marginTop:6}}>
                        <img src={calender} width={17} alt="Calender icon" />
                    </div>
                    <div className="inputdetails">
                        <div className="cityname">
                            <DatePicker
                                format="D MMMM YYYY"
                                style={{
                                    width: "100%",
                                    border: "none",
                                    fontFamily: "DM Sans",
                                    fontSize: 14.5,
                                    padding: 0,
                                    letterSpacing: "-0.4px",
                                    margin: 0,
                                    outline: "none",
                                    lineHeight: 12,
                                    boxShadow: "none",
                                    color: "black",
                                    background: "none",
                                    cursor: "pointer",
                                }}
                                // range
                                numberOfMonths={2}
                                minDate={new Date(Date.now() - 864e5)}
                                value={new Date(departureDate)}
                                onChange={handleDateChange}
                                className="teal"
                            />
                        </div>
                        <div className="statename" style={{paddingTop:5}}>
                            <span className="textstatename">
                                {formatDay(departureDate)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="seperation">
                <div className="innerseperation"></div>
            </div>
            <div className="adult-container">
                <div className="searchselection">
                    <div className="text">
                        <span className="from">No. of travellers</span>
                    </div>
                    <div className="input">
                        <div className="icon">
                            <img src={traveller} width={17} alt="Traveller icon" />
                        </div>
                        <div
                            className="inputdetails"
                            style={{ cursor: "pointer" }}
                        >
                            <div
                                className="cityname"
                                onClick={() => {
                                    setAdultsOpen(!adultsOpen);
                                }}
                            >
                                <span className="textcityname-x">
                                    {adults === 1 ? (
                                        <>{adults} Traveller</>
                                    ) : (
                                        <>{adults} Travellers</>
                                    )}
                                </span>
                            </div>
                            <div className="statename">
                                <span className="textstatename">
                                    {adults === 1 ? (
                                        <>{adults} Adult</>
                                    ) : (
                                        <>{adults} Adults</>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {adultsOpen && (
                    <div className="adult-pop">
                        <div className="containerx">
                            <p className="title">Adults</p>
                            <div className="button-container">
                                <button
                                    onClick={() => {
                                        setAdults(adults > 1 ? adults - 1 : 1); // Ensure adults don't go negative
                                    }}
                                    className="button-top"
                                >
                                    -
                                </button>
                                <p className="adult-count">
                                    {adults.toString().padStart(2, "0")}
                                </p>
                                <button
                                    onClick={() => {
                                        setAdults(
                                            adults <
                                                parseInt(
                                                    process.env
                                                        .REACT_APP_MAX_ADULTS ??
                                                        "6"
                                                )
                                                ? adults + 1
                                                : adults
                                        );
                                    }}
                                    className="button-top"
                                >
                                    +
                                </button>
                                <p className="adult-info">16+ years</p>
                            </div>
                            <div className="footer">
                                <button
                                    onClick={() => {
                                        setAdultsOpen(!adultsOpen);
                                    }}
                                    className="done-button"
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="searchbuttoncontainer">
                <div className="searchbutton" onClick={handleClickFunction}>
                    <span className="searchtext">Search</span>
                </div>
            </div>
        </div>
    );
}
