import Trainy from "../../assets/vectors1/Trainy.svg";
import Plane from "../../assets/vectors1/Plane.svg";
import Adcount from "../../assets/vectors1/Adcount.svg";
import Next from "../../assets/vectors/Vector9_x2.svg";
import "../../style/global.css";
import leftline from "../../assets/vectors/Vector T, F left.svg";
import flight from "../../assets/vectors/newflight.svg";
import rightline from "../../assets/vectors/Vector T, F right.svg";
import leftlinetft from "../../assets/vectors/vector tft left.svg";
import train from "../../assets/vectors/newtrain.svg";
import rightlinetft from "../../assets/vectors/Vector tft right.svg";
import leftline_tt from "../../assets/vectors/Vector tt left.svg";
import rightline_tt from "../../assets/vectors/Vector tt right.svg";
import {
  DirectFlightModel,
  FlightTrainModel,
  IndirectFlightModel,
  TrainModel,
  TrainTrainModel,
  TrainFlightTrainModel,
} from "../../utils/models_types";
interface ModeCardProps {
  mode: string;
  index: number;
  data:
    | TrainModel[]
    | (DirectFlightModel | IndirectFlightModel)[]
    | FlightTrainModel[]
    | TrainFlightTrainModel[]
    | TrainTrainModel[];
  fromCity: string;
  midCity: string;
  toCity: string;
  departureDate: string;
  adults: number;
  handleViewDetails: (
    mode: string,
    intermediate: string,
    minDateData:
      | TrainModel
      | TrainTrainModel
      | FlightTrainModel
      | TrainFlightTrainModel
      | (DirectFlightModel | IndirectFlightModel)
  ) => void;
}

const ModeCard: React.FC<ModeCardProps> = ({
  mode,
  index,
  data,
  fromCity,
  midCity,
  toCity,
  departureDate,
  adults,
  handleViewDetails,
}) => {
  const findMinPrice = (
    data: Array<
      | TrainModel
      | DirectFlightModel
      | IndirectFlightModel
      | FlightTrainModel
      | TrainTrainModel
      | TrainFlightTrainModel
    >
  ) => {
    if (data == undefined) return null;
    if (data.length === 0) return null; // Return null if the array is empty

    return data.reduce((minItem, currentItem) => {
      const minPrice = minItem.total_price ?? Infinity; // Handle undefined total_price
      const currentPrice = currentItem.total_price ?? Infinity; // Handle undefined total_price

      return currentPrice < minPrice ? currentItem : minItem;
    });
  };

  const findMinTime = (
    data: Array<
      | TrainModel
      | DirectFlightModel
      | IndirectFlightModel
      | FlightTrainModel
      | TrainTrainModel
      | TrainFlightTrainModel
    >
  ) => {
    if (data.length === 0) return null; // Return null if the array is empty
    return (
      data.length > 0 &&
      data.reduce((minItem, currentItem) =>
        (currentItem.total_duration ?? Infinity) <
        (minItem.total_duration ?? Infinity)
          ? currentItem
          : minItem
      )
    );
  };

  const minDate = (
    data: Array<
      | TrainModel
      | DirectFlightModel
      | IndirectFlightModel
      | FlightTrainModel
      | TrainTrainModel
      | TrainFlightTrainModel
    >
  ) => {
    return (
      data.length > 0 &&
      data.reduce((minItem, currentItem) =>
        (
          mode === "train"
            ? (currentItem as TrainModel).properties.arrival_time <
              (minItem as TrainModel).properties.arrival_time
            : mode === "flight" &&
              (minItem as DirectFlightModel).properties.stop_count === 0
            ? (currentItem as DirectFlightModel).properties.arrival_time <
              (minItem as DirectFlightModel).properties.arrival_time
            : mode === "flight" &&
              (minItem as IndirectFlightModel).properties.stop_count >= 1
            ? (currentItem as IndirectFlightModel).properties.segments?.[
                (currentItem as IndirectFlightModel).properties.segments
                  ?.length - 1
              ]?.arrival_time <
              (minItem as IndirectFlightModel).properties.segments?.[
                (minItem as IndirectFlightModel).properties.segments?.length - 1
              ]?.arrival_time
            : mode === "trainFlight"
            ? (currentItem as FlightTrainModel).flight.properties.arrival_time <
              (minItem as FlightTrainModel).flight.properties.arrival_time
            : mode === "flightTrain"
            ? (currentItem as FlightTrainModel).train.properties.arrival_time <
              (minItem as FlightTrainModel).train.properties.arrival_time
            : (currentItem as TrainTrainModel).train2.properties.arrival_time <
              (minItem as TrainTrainModel).train2.properties.arrival_time
        )
          ? currentItem
          : minItem
      )
    );
  };
  const formatDay = (dateString: number | string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { weekday: "short" });
  };

  const formatDateMonth = (dateString: number | string) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const dayOfMonth = date.getDate();

    const nth = (d: number) => {
      if (d > 3 && d < 21) return "ᵗʰ";
      switch (d % 10) {
        case 1:
          return "ˢᵗ";
        case 2:
          return "ⁿᵈ";
        case 3:
          return "ʳᵈ";
        default:
          return "ᵗʰ";
      }
    };

    return `${dayOfMonth}${nth(dayOfMonth)} ${month}`;
  };

  // const formatDate = (dateString: number | string) => {
  //   const date = new Date(dateString);

  //   const day = date.toLocaleDateString("en-US", { weekday: "short" });
  //   const month = date.toLocaleString("default", { month: "short" });

  //   const dayOfMonth = date.getDate();
  //   // const year = date.getFullYear();

  //   const nth = (d: number) => {
  //     if (d > 3 && d < 21) return "th";
  //     switch (d % 10) {
  //       case 1:
  //         return "st";
  //       case 2:
  //         return "nd";
  //       case 3:
  //         return "rd";
  //       default:
  //         return "th";
  //     }
  //   };

  //   return `${day}, ${dayOfMonth}${nth(dayOfMonth)} ${month}`;
  // };

  const convertDurationToHours = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const minData = findMinPrice(data);
  const minTimeData = findMinTime(data);
  const minDateData = minDate(data);

  return (
    <div key={index} className="f-2">
      <div className="mainmodecard">
        <div className="modedetails">
          <div className="innermodedetails">
            <div
              className={`citynameleft ${
                mode === "trainFlightTrain" ? "w-[75px]" : "flex-1"
              }`}
            >
              <span className="citynamelefttext capitalize">
                {mode === "train"
                  ? (
                      minData as TrainModel
                    ).properties.origin_city?.toLowerCase()
                  : mode === "flight" &&
                    (minData as IndirectFlightModel).properties.stop_count >= 1
                  ? (
                      minData as IndirectFlightModel
                    ).properties.segments[0].origin_city?.toLowerCase()
                  : mode === "flight" &&
                    (minData as DirectFlightModel).properties.stop_count === 0
                  ? (
                      minData as DirectFlightModel
                    ).properties.origin_city?.toLowerCase()
                  : mode === "trainFlight"
                  ? (
                      minData as FlightTrainModel
                    ).train.properties.origin_city?.toLowerCase()
                  : mode === "flightTrain"
                  ? (minData as FlightTrainModel).flight.properties.origin.city
                  : mode === "trainFlightTrain" || mode === "trainTrain"
                  ? (
                      minData as TrainTrainModel | TrainFlightTrainModel
                    ).train1.properties.origin_city?.toLowerCase()
                  : fromCity}
              </span>
            </div>
            <div className="onemodetravel">
              <div className="onemodetravelinner">
                {/* <img src={leftline}></img> */}
                <div
                  className={`left-line ${
                    mode === "train" || mode === "flight"
                      ? "w-[83px]"
                      : mode === "trainFlightTrain"
                      ? "w-[49px]"
                      : "w-[69px]"
                  }`}
                ></div>
                {/* <img src={flight}></img> */}
                <img
                  // className="vector-6"
                  src={
                    mode === "flight" || mode === "flightTrain" ? flight : train
                  }
                  alt="plane or train icon"
                />
                {/* <img src={rightline}></img> */}
                <div
                  className={`right-line ${
                    mode === "train" || mode === "flight"
                      ? "w-[83px]"
                      : mode === "trainFlightTrain"
                      ? "w-[49px]"
                      : "w-[69px]"
                  }`}
                ></div>
              </div>
              <div
                className={`dateandday ${
                  mode === "train" || mode === "flight"
                    ? "gap-[116px]"
                    : mode === "trainFlightTrain"
                    ? "gap-[49px]"
                    : "gap-[88px]"
                }`}
              >
                <div className="dateanddayinner">
                  <div className="daydivtext">
                    <span className="daytext">{formatDay(departureDate)}</span>
                  </div>
                  <div className="datetextdiv">
                    <span className="datetext">
                      {" "}
                      {formatDateMonth(departureDate)}
                    </span>
                  </div>
                </div>
                <div className="dateanddayinner">
                  <div className="daydivtext">
                    <span className="daytext">
                      {formatDay(
                        mode === "train"
                          ? (minDateData as TrainModel).properties.arrival_time
                          : mode === "flight" &&
                            (minDateData as DirectFlightModel).properties
                              .stop_count === 0
                          ? (minDateData as DirectFlightModel).properties
                              .arrival_time
                          : mode === "flight" &&
                            (minDateData as IndirectFlightModel).properties
                              .stop_count >= 1
                          ? (minDateData as IndirectFlightModel).properties
                              ?.segments?.[
                              (minDateData as IndirectFlightModel).properties
                                ?.segments?.length - 1
                            ].arrival_time
                          : departureDate
                      )}
                    </span>
                  </div>
                  <div className="datetextdiv">
                    <span className="datetext">
                      {formatDateMonth(
                        mode === "train"
                          ? (minDateData as TrainModel).properties.arrival_time
                          : mode === "flight" &&
                            (minDateData as DirectFlightModel).properties
                              .stop_count === 0
                          ? (minDateData as DirectFlightModel).properties
                              .arrival_time
                          : mode === "flight" &&
                            (minDateData as IndirectFlightModel).properties
                              .stop_count >= 1
                          ? (minDateData as IndirectFlightModel).properties
                              ?.segments?.[
                              (minDateData as IndirectFlightModel).properties
                                ?.segments?.length - 1
                            ].arrival_time
                          : departureDate
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {(mode === "flightTrain" ||
              mode === "trainFlight" ||
              mode === "trainFlightTrain" ||
              mode === "trainTrain") && (
              <>
                <div className="citynamemiddelcontainer">
                  <div className="innercitynamemiddel">
                    <span className="middlecitynametft capitalize">
                      {mode === "trainFlightTrain"
                        ? (
                            minData as TrainFlightTrainModel
                          ).intermediate1?.toLowerCase()
                        : (
                            minData as TrainTrainModel | FlightTrainModel
                          ).intermediate?.toLowerCase()}
                    </span>
                  </div>
                </div>
                <div className="TTtrainicon">
                  <div className="TTtraininnericon">
                    {/* <img src={leftline_tt} /> */}
                    <div
                      className={`left-line ${
                        mode === "trainFlightTrain" ? "w-[49px]" : "w-[69px]"
                      }`}
                    ></div>
                    {/* <img src={train} /> */}
                    <img
                      src={
                        mode === "trainTrain" || mode === "flightTrain"
                          ? train
                          : flight
                      }
                    />
                    {/* <img src={rightline_tt} /> */}
                    <div
                      className={`right-line ${
                        mode === "trainFlightTrain" ? "w-[49px]" : "w-[69px]"
                      }`}
                    ></div>
                  </div>
                  <div
                    className={`TTdaydatetft ${
                      mode === "trainFlightTrain" ? "gap-[49px]" : "gap-[88px]"
                    }`}
                  >
                    <div className="TTdaydateleft">
                      <div className="TTdaytft">
                        <span className="TTdaytexttft">
                          {formatDay(
                            mode === "trainFlightTrain"
                              ? (minData as TrainFlightTrainModel).flight
                                  .properties.departure_time
                              : mode === "trainTrain"
                              ? (minData as TrainTrainModel).train2.properties
                                  .departure_time
                              : mode === "flightTrain"
                              ? (minData as FlightTrainModel).train.properties
                                  .departure_time
                              : (minData as FlightTrainModel).flight.properties
                                  .departure_time
                          )}
                        </span>
                      </div>
                      <div className="datetft">
                        <span className="datetexttft">
                          {formatDateMonth(
                            mode === "trainFlightTrain"
                              ? (minData as TrainFlightTrainModel).flight
                                  .properties.departure_time
                              : mode === "trainTrain"
                              ? (minData as TrainTrainModel).train2.properties
                                  .departure_time
                              : mode === "flightTrain"
                              ? (minData as FlightTrainModel).train.properties
                                  .departure_time
                              : (minData as FlightTrainModel).flight.properties
                                  .departure_time
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="daydateright">
                      <div className="daytft">
                        <span className="daytexttft">
                          {formatDay(
                            mode === "trainFlightTrain"
                              ? (minData as TrainFlightTrainModel).flight
                                  .properties.arrival_time
                              : mode === "trainTrain"
                              ? (minData as TrainTrainModel).train2.properties
                                  .arrival_time
                              : mode === "flightTrain"
                              ? (minData as FlightTrainModel).train.properties
                                  .arrival_time
                              : (minData as FlightTrainModel).flight.properties
                                  .arrival_time
                          )}
                        </span>
                      </div>
                      <div className="datetft">
                        <span className="datetexttft">
                          {formatDateMonth(
                            mode === "trainFlightTrain"
                              ? (minData as TrainFlightTrainModel).flight
                                  .properties.arrival_time
                              : mode === "trainTrain"
                              ? (minData as TrainTrainModel).train2.properties
                                  .arrival_time
                              : mode === "flightTrain"
                              ? (minData as FlightTrainModel).train.properties
                                  .arrival_time
                              : (minData as FlightTrainModel).flight.properties
                                  .arrival_time
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {mode === "trainFlightTrain" && (
              <>
                <div className="citynamemiddelcontainer">
                  <div className="innercitynamemiddel">
                    <span className="middlecitynametft capitalize">
                      {(
                        minData as TrainFlightTrainModel
                      ).intermediate2?.toLowerCase()}
                    </span>
                  </div>
                </div>
                <div className="trainicon">
                  <div className="traininnericon">
                    {/* <img src={leftlinetft} /> */}
                    <div
                      className={`left-line ${
                        mode === "trainFlightTrain" ? "w-[49px]" : "w-[69px]"
                      }`}
                    ></div>
                    <img src={train} />
                    {/* <img src={rightlinetft} /> */}
                    <div
                      className={`right-line ${
                        mode === "trainFlightTrain" ? "w-[49px]" : "w-[69px]"
                      }`}
                    ></div>
                  </div>
                  <div
                    className={`daydatetft ${
                      mode === "trainFlightTrain" ? "gap-[49px]" : "gap-[88px]"
                    }`}
                  >
                    <div className="daydateleft">
                      <div className="daytft">
                        <span className="daytexttft">
                          {formatDay(
                            (minData as TrainFlightTrainModel).train2.properties
                              .departure_time
                          )}
                        </span>
                      </div>
                      <div className="datetft">
                        <span className="datetexttft">
                          {formatDateMonth(
                            (minData as TrainFlightTrainModel).train2.properties
                              .departure_time
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="daydateright">
                      <div className="daytft">
                        <span className="daytexttft">
                          {formatDay(
                            (minData as TrainFlightTrainModel).train2.properties
                              .arrival_time
                          )}
                        </span>
                      </div>
                      <div className="datetft">
                        <span className="datetexttft">
                          {formatDateMonth(
                            (minData as TrainFlightTrainModel).train2.properties
                              .arrival_time
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className={`cityynameright ${
                mode === "trainFlightTrain" ? "w-[75px]" : "flex-1"
              }`}
            >
              <span className="citynamerighttext capitalize">
                {mode === "train"
                  ? (
                      minData as TrainModel
                    ).properties.destination_city?.toLowerCase()
                  : mode === "flight" &&
                    (minData as IndirectFlightModel).properties.stop_count >= 1
                  ? (minData as IndirectFlightModel).properties.segments[
                      (minData as IndirectFlightModel).properties?.segments
                        ?.length - 1
                    ].destination_city?.toLowerCase()
                  : mode === "flight" &&
                    (minData as DirectFlightModel).properties.stop_count === 0
                  ? (
                      minData as DirectFlightModel
                    ).properties.destination_city?.toLowerCase()
                  : mode === "trainFlightTrain" || mode === "trainTrain"
                  ? (
                      minData as TrainTrainModel | TrainFlightTrainModel
                    ).train2.properties.destination_city?.toLowerCase()
                  : mode === "trainFlight"
                  ? (
                      minData as FlightTrainModel
                    ).flight.properties.destination_city?.toLowerCase()
                  : mode === "flightTrain"
                  ? (
                      minData as FlightTrainModel
                    ).train.properties.destination_city?.toLowerCase()
                  : toCity?.toLowerCase()}
              </span>
            </div>
          </div>
        </div>

        <div className="modeprices">
          <div className="arrivalstartcard">
            <div className="arrivalsearchinnercard">
              <div className="approxtimecard">
                <span className="apporxtimetext">
                  Approx Time :{" "}
                  <span className="timestyle">
                    {convertDurationToHours(
                      (
                        minTimeData as
                          | TrainModel
                          | DirectFlightModel
                          | IndirectFlightModel
                          | FlightTrainModel
                          | TrainTrainModel
                          | TrainFlightTrainModel
                      ).total_duration ?? 0
                    )}
                  </span>
                </span>
              </div>

              <div className="startfromcard">
                <div className="startfrominnercard">
                  <span className="startfromtext">Starting From :</span>
                </div>
                <div className="proicepersoncard">
                  <span className="pricetextvalue">
                    {" "}
                    ₹{" "}
                    {mode === "train" || mode === "trainTrain"
                      ? "250"
                      : (
                          minData as
                            | TrainModel
                            | DirectFlightModel
                            | IndirectFlightModel
                            | FlightTrainModel
                            | TrainTrainModel
                            | TrainFlightTrainModel
                        ).total_price?.toLocaleString() === "0"
                      ? "--"
                      : (
                          minData as
                            | TrainModel
                            | DirectFlightModel
                            | IndirectFlightModel
                            | FlightTrainModel
                            | TrainTrainModel
                            | TrainFlightTrainModel
                        ).total_price?.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="view-buttondetails">
            <button
              className="viewdetailsbutton"
              onClick={() => {
                if (minDateData) {
                  handleViewDetails(
                    mode,
                    "intermediate" in data[0]
                      ? data[0]?.intermediate
                      : "intermediate1" in data[0]
                      ? data[0]?.intermediate1 + "&" + data[0]?.intermediate2
                      : "none",
                    minDateData
                  );
                }
              }}
            >
              <span className="buttonstyle">View Details</span>
            </button>
          </div>
        </div>
      </div>

      {/* <div>this is shivkiran</div>
        <div className="mainmodecard">
          <div className="modedetailstft">
            <div className="innermodedetailstft">
                <div className="citynamemiddelcontainer">
                   
                        <span className="TTcitynamelefttext">Nanded</span>

                    

                </div>
                <div className="TTtrainicon">
                    <div className="TTtraininnericon">
                        <img src={leftline_tt}/>
                        <img src={train} />
                        <img src={rightline_tt} />

                    </div>
                    <div className="TTdaydatetft">
                        <div className="TTdaydateleft">
                            <div className="TTdaytft">
                                <span className="TTdaytexttft">Wed</span>
                            </div>
                            <div className="datetft">
                                <span className="datetexttft">15 May, 24</span>
                            </div>

                        </div>
                        <div className="daydateright">
                        <div className="daytft">
                                <span className="daytexttft">Wed</span>
                            </div>
                            <div className="datetft">
                                <span className="datetexttft">15 May, 24</span>
                            </div>

                        </div>

                    </div>

                </div>
                <div className="citynamemiddelcontainer">
                    <div className="innercitynamemiddel">
                        <span className="middlecitynametft">New Delhi</span>

                    </div>

                </div>
                <div className="TTtrainicon">
                    <div className="TTtraininnericon">
                        <img src={leftline_tt}/>
                        <img src={train} />
                        <img src={rightline_tt} />

                    </div>
                    <div className="TTdaydatetft">
                        <div className="TTdaydateleft">
                            <div className="TTdaytft">
                                <span className="TTdaytexttft">Wed</span>
                            </div>
                            <div className="datetft">
                                <span className="datetexttft">15 May, 24</span>
                            </div>

                        </div>
                        <div className="daydateright">
                        <div className="daytft">
                                <span className="daytexttft">Wed</span>
                            </div>
                            <div className="datetft">
                                <span className="datetexttft">15 May, 24</span>
                            </div>

                        </div>

                    </div>

                </div>
                <div className="citynamemiddelcontainer">
                    <div className="innercitynamemiddel">
                        <span className="middlecitynametft">New Delhi</span>

                    </div>

                </div>

            </div>
          </div>
          <div className="modeprices">
            <div className="arrivalstartcard">
              <div className="arrivalsearchinnercard">
                <div className="approxtimecard">
                  <span className="apporxtimetext">
                    Approx Time : <span className="timestyle">28h 10m</span>
                  </span>
                </div>

                <div className="startfromcard">
                  <div className="startfrominnercard">
                    <span className="startfromtext">Start From :</span>
                  </div>
                  <div className="proicepersoncard">
                    <span className="pricetextvalue">₹1825</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="view-buttondetails">
              <button className="viewdetailsbutton">
                <span className="buttonstyle">View Details</span>
              </button>
            </div>
          </div>
        </div> */}

      {/* <div className="flight-details">
        <div className="mode-main">
          <div className="single-city">
            <div className="city-name-8">
              <span className="vijayawada-6 capitalize">
                {mode === "train"
                  ? (
                      minData as TrainModel
                    ).properties.origin_city?.toLowerCase()
                  : mode === "flight" &&
                    (minData as IndirectFlightModel).properties.stop_count >= 1
                  ? (
                      minData as IndirectFlightModel
                    ).properties.segments[0].origin_city?.toLowerCase()
                  : mode === "flight" &&
                    (minData as DirectFlightModel).properties.stop_count === 0
                  ? (
                      minData as DirectFlightModel
                    ).properties.origin_city?.toLowerCase()
                  : mode === "trainFlight"
                  ? (
                      minData as FlightTrainModel
                    ).train.properties.origin_city?.toLowerCase()
                  : mode === "flightTrain"
                  ? (minData as FlightTrainModel).flight.properties.origin.city
                  : mode === "trainFlightTrain" || mode === "trainTrain"
                  ? (
                      minData as TrainTrainModel
                    ).train1.properties.origin_city?.toLowerCase()
                  : fromCity}
              </span>
            </div>
            <div className="day-date-4">
              <span className="dates-depart-arrival">
                {formatDate(departureDate)}
              </span>
            </div>
          </div>
          <div className="mode-icon">
            <div className="dotted-line"></div>
            <div className="frame-1">
              <img
                className="vector-6"
                src={
                  mode === "flight" || mode === "flightTrain" ? Plane : Trainy
                }
                alt="plane or train icon"
              />
            </div>
            <div className="dotted-line-1"></div>
          </div>
          <div className="single-city">
            <div className="city-name-8">
              <span className="vijayawada-7 capitalize">
                {mode === "train"
                  ? (
                      minData as TrainModel
                    ).properties.destination_city?.toLowerCase()
                  : mode === "flight" &&
                    (minData as IndirectFlightModel).properties.stop_count >= 1
                  ? (minData as IndirectFlightModel).properties.segments[
                      (minData as IndirectFlightModel).properties?.segments
                        ?.length - 1
                    ].destination_city?.toLowerCase()
                  : mode === "flight" &&
                    (minData as DirectFlightModel).properties.stop_count === 0
                  ? (
                      minData as DirectFlightModel
                    ).properties.destination_city?.toLowerCase()
                  : mode === "trainFlight" ||
                    mode === "trainTrain" ||
                    mode === "flightTrain"
                  ? (
                      minData as TrainTrainModel | FlightTrainModel
                    ).intermediate?.toLowerCase()
                  : fromCity}
              </span>
            </div>
            <div className="day-date-4">
              <span className="dates-depart-arrival">
                {formatDate(
                  mode === "train"
                    ? (minDateData as TrainModel).properties.arrival_time
                    : mode === "flight" &&
                      (minDateData as DirectFlightModel).properties
                        .stop_count === 0
                    ? (minDateData as DirectFlightModel).properties.arrival_time
                    : mode === "flight" &&
                      (minDateData as IndirectFlightModel).properties
                        .stop_count >= 1
                    ? (minDateData as IndirectFlightModel).properties
                        ?.segments?.[
                        (minDateData as IndirectFlightModel).properties
                          ?.segments?.length - 1
                      ].arrival_time
                    : departureDate
                )}
              </span>
            </div>
          </div>
          {(mode === "flightTrain" ||
            mode === "trainFlight" ||
            mode === "trainTrain") && (
            <>
              <div className="mode-icon">
                <div className="dotted-line"></div>
                <div className="frame-1">
                  <img
                    className="vector-6"
                    src={
                      mode === "trainTrain" || mode === "flightTrain"
                        ? Trainy
                        : Plane
                    }
                  />
                </div>
                <div className="dotted-line-1"></div>
              </div>
              <div className="single-city">
                <div className="city-name-8">
                  <span className="vijayawada-8 capitalize">
                    {mode === "trainFlight"
                      ? (
                          minData as FlightTrainModel
                        ).flight.properties.destination_city?.toLowerCase()
                      : mode === "trainTrain"
                      ? (
                          minData as TrainTrainModel
                        ).train2.properties.destination_city?.toLowerCase()
                      : mode === "flightTrain"
                      ? (
                          minData as FlightTrainModel
                        ).train.properties.destination_city?.toLowerCase()
                      : toCity}
                  </span>
                </div>
                <div className="day-date-4">
                  <span className="dates-depart-arrival">
                    {formatDate(
                      mode === "trainFlight"
                        ? (minDateData as FlightTrainModel).flight.properties
                            .arrival_time
                        : mode === "flightTrain"
                        ? (minDateData as FlightTrainModel).train.properties
                            .arrival_time
                        : (minDateData as TrainTrainModel).train2.properties
                            .arrival_time
                    )}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="price">
          <div className="view-details">
            <div className="starting-from">
              <span className="starting-from-1">Starting From</span>
            </div>
            <div className="starting-amount">
              <span className="container">
                ₹{" "}
                {(
                  minData as
                    | TrainModel
                    | DirectFlightModel
                    | IndirectFlightModel
                    | FlightTrainModel
                    | TrainTrainModel
                ).total_price?.toLocaleString() === "0"
                  ? "--"
                  : (
                      minData as
                        | TrainModel
                        | DirectFlightModel
                        | IndirectFlightModel
                        | FlightTrainModel
                        | TrainTrainModel
                    ).total_price?.toLocaleString()}
              </span>
            </div>
            <div className="starting-adults">
              <div className="container-1">[</div>
              <div className="group-88">
                <img className="icon-1" src={Adcount} />
                <span className="container-3">:</span>
                <div className="container-2">{adults}</div>
              </div>
              <div className="container-4">]</div>
            </div>
          </div>
          <div className="f-2-deets">
            <div className="aprox-timre">
              <span className="approx-time-28-h-10-m">
                Approx Time :{" "}
                {convertDurationToHours(
                  (
                    minTimeData as
                      | TrainModel
                      | DirectFlightModel
                      | IndirectFlightModel
                      | FlightTrainModel
                      | TrainTrainModel
                  ).total_duration ?? 0
                )}
              </span>
            </div>
            <div className="view-details-btn">
              <button
                className="view-details-txt"
                onClick={() => {
                  handleViewDetails(
                    mode,
                    "intermediate" in data[0] ? data[0]?.intermediate : "none"
                  );
                }}
              >
                <span className="view-details-1">View Details</span>
              </button>
              <div className="bx-right-arrow-alt">
                <img className="vector-3" src={Next} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vector-23"></div> */}
    </div>
  );
};

export default ModeCard;
