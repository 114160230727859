import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Styler from "./street/style.json";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMapanimatestart } from "../store/dataSlice";

mapboxgl.accessToken =
  "pk.eyJ1Ijoid2F5cG9ydC13YXlwb3J0IiwiYSI6ImNtMmtjYW5zNjA1cmIyc3NjaTJhMGNzanQifQ.2hORGRlYUxSDDndrA4BmoQ";

const MapComponent = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const mapProps = useSelector((state) => state.data.mapProps);
  const mapanimatestart = useSelector((state) => state.data.mapanimatestart);
  const dateWiseData = useSelector((state) => state.data.dateWiseData);

  const {
    sourceLocation,
    destinationLocation,
    intermediate1Location,
    intermediate2Location,
    intermediateD1Location,
    intermediateD2Location,
    models,
  } = mapProps;

  const [showMap, setShowMap] = useState(true);
  const [mapRef, setMapRef] = useState(null);
  const mapContainerRef = useRef(null);
  const markersRef = useRef({});
  const animationFrameRef = useRef(null);

  console.log("Models are as follows ----> ", models);

  const TF = models?.includes(4);
  const FT = models?.includes(5);
  const TFT = models?.includes(6);

  const INITIAL_VIEW = {
    center: [-95.712891, 37.09024],
    zoom: 0.8,
    pitch: 0,
    bearing: 0,
  };

  const hasValidProps = () => {
    return (
      sourceLocation?.coordinates &&
      destinationLocation?.coordinates &&
      intermediate1Location?.coordinates &&
      intermediate2Location?.coordinates &&
      intermediateD1Location?.coordinates &&
      intermediateD2Location?.coordinates
    );
  };

  const calculateBounds = () => {
    if (!hasValidProps()) return null;

    const coordinates = [
      sourceLocation.coordinates,
      destinationLocation.coordinates,
      intermediate1Location.coordinates,
      intermediate2Location.coordinates,
      intermediateD1Location.coordinates,
      intermediateD2Location.coordinates,
    ];

    const bounds = coordinates.reduce(
      (bounds, coord) => {
        return {
          minLng: Math.min(bounds.minLng, coord[0]),
          maxLng: Math.max(bounds.maxLng, coord[0]),
          minLat: Math.min(bounds.minLat, coord[1]),
          maxLat: Math.max(bounds.maxLat, coord[1]),
        };
      },
      {
        minLng: 180,
        maxLng: -180,
        minLat: 90,
        maxLat: -90,
      }
    );

    // Add padding to bounds
    const padding = 1; // degrees
    return new mapboxgl.LngLatBounds(
      [bounds.minLng - padding, bounds.minLat - padding],
      [bounds.maxLng + padding, bounds.maxLat + padding]
    );
  };

  const createArc = (start, end, numPoints = 100, heightMultiplier = 0.3) => {
    const line = [];
    for (let i = 0; i <= numPoints; i++) {
      const t = i / numPoints;
      const lon = start[0] + t * (end[0] - start[0]);
      const lat = start[1] + t * (end[1] - start[1]);

      const arc =
        Math.sin(Math.PI * t) *
        heightMultiplier *
        Math.sqrt(
          Math.pow(end[0] - start[0], 2) + Math.pow(end[1] - start[1], 2)
        );

      line.push([lon, lat + arc]);
    }
    return line;
  };

  const createBlinkingMarker = (coords) => {
    const markerEl = document.createElement("div");
    markerEl.className = "blinking-marker";

    const style = document.createElement("style");
    style.textContent = `
      .blinking-marker {
        width: 16px;
        height: 16px;
        background: radial-gradient(circle, rgba(9,4,101,1) 0%, rgba(9,9,121,1) 5%, rgba(4,6,94,1) 9%, rgba(0,4,70,0.17471988795518212) 77%, rgba(0,212,255,0) 100%);
        border-radius: 50%;
        animation: blink 1s infinite;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
      }
      .blinking-marker.visible {
        opacity: 1;
      }
      @keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0.4; }
        100% { opacity: 1; }
      }
    `;
    document.head.appendChild(style);

    return markerEl;
  };

  const createLabel = (name) => {
    const labelEl = document.createElement("div");
    labelEl.className = "location-label";
    labelEl.textContent = name;

    const style = document.createElement("style");
    style.textContent = `
      .location-label {
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 10px;
        font-weight: bold;
        transform: translate(-50%, -100%);
        white-space: nowrap;
        margin-top: 10px;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
      }
      .location-label.visible {
        opacity: 1;
      }
    `;
    document.head.appendChild(style);

    return labelEl;
  };

  const addMarkerWithLabel = (map, coords, name, key, visible = false) => {
    const markerEl = createBlinkingMarker(coords);
    const labelEl = createLabel(name);

    if (visible) {
      markerEl.classList.add("visible");
      labelEl.classList.add("visible");
    }

    const marker = new mapboxgl.Marker(markerEl).setLngLat(coords).addTo(map);

    const label = new mapboxgl.Marker(labelEl)
      .setLngLat(coords)
      .setOffset([0, 8])
      .addTo(map);

    markersRef.current[key] = {
      marker,
      label,
      markerEl,
      labelEl,
    };
  };

  const showMarker = (key) => {
    if (markersRef.current[key]) {
      const { markerEl, labelEl } = markersRef.current[key];
      markerEl.classList.add("visible");
      labelEl.classList.add("visible");
    }
  };

  const animatePath = (
    map,
    lineCoordinates,
    pathId,
    checkPoint,
    onReachCheckpoint
  ) => {
    let i = 0;
    const coordinates = [];
    const POINTS_PER_FRAME = 2;
    let checkpointReached = false;

    const animateStep = () => {
      for (
        let step = 0;
        step < POINTS_PER_FRAME && i < lineCoordinates.length;
        step++
      ) {
        coordinates.push(lineCoordinates[i]);

        if (
          !checkpointReached &&
          checkPoint &&
          Math.abs(lineCoordinates[i][0] - checkPoint[0]) < 0.01 &&
          Math.abs(lineCoordinates[i][1] - checkPoint[1]) < 0.01
        ) {
          checkpointReached = true;
          onReachCheckpoint();
        }

        i++;
      }

      const source = map.getSource(pathId);
      if (source) {
        source.setData({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
        });
      }

      if (i < lineCoordinates.length) {
        animationFrameRef.current = requestAnimationFrame(animateStep);
      }
    };

    animateStep();
  };

  const initializeAnimationSequence = async () => {
    if (!hasValidProps()) return;
    await new Promise((resolve) => setTimeout(resolve, 1000));

    mapRef.easeTo({
      center: [78.9629, 20.5937],
      zoom: 2,
      duration: 2000,
      curve: 1,
      pitch: 0,
      easing(t) {
        const x1 = 1,
          y1 = 0.01,
          x2 = 0.2,
          y2 = 1;
        return (
          3 * Math.pow(1 - t, 2) * t * y1 +
          3 * (1 - t) * Math.pow(t, 2) * y2 +
          Math.pow(t, 3)
        );
      },
    });

    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Smooth zoom to fit all points
    const bounds = calculateBounds();
    if (!FT && !TF) {
      mapRef.fitBounds(bounds, {
        padding: 100,
        duration: 2000,
        pitch: 45,
        bearing: -11.7,
        maxZoom: 4.9,
      });
    } else {
      mapRef.fitBounds(bounds, {
        padding: 100,
        duration: 3000,
        pitch: 45,
        bearing: -11.7,
        maxZoom: 4.9,
      });
    }

    // Wait for zoom animation to complete
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Show source and destination markers with a slight delay
    addMarkerWithLabel(
      mapRef,
      sourceLocation.coordinates,
      sourceLocation.name,
      "source",
      false
    );
    addMarkerWithLabel(
      mapRef,
      destinationLocation.coordinates,
      destinationLocation.name,
      "destination",
      false
    );
    setTimeout(() => {
      showMarker("source");
      showMarker("destination");
    }, 3000);

    const pathF = createArc(
      sourceLocation.coordinates,
      destinationLocation.coordinates
    );

    // Add source and layer for pathF
    mapRef.addSource("pathF", {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      },
    });

    mapRef.addLayer({
      id: "pathF",
      type: "line",
      source: "pathF",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#FD1d1d",
        "line-width": 3,
        "line-opacity": 1,
      },
    });

    // Animate pathF
    await new Promise((resolve) => {
      animatePath(mapRef, pathF, "pathF", null, () => {});
      const checkCompletion = () => {
        const source = mapRef.getSource("pathF");
        if (
          source &&
          source._data.geometry.coordinates.length === pathF.length
        ) {
          resolve();
        } else {
          setTimeout(checkCompletion, 100);
        }
      };
      checkCompletion();
    });

    // Helper function to create a promise that resolves when path animation is complete
    const createAnimationPromise = (
      mapRef,
      path,
      pathId,
      intermediateCoords,
      markerCallback
    ) => {
      return new Promise((resolve) => {
        animatePath(mapRef, path, pathId, intermediateCoords, markerCallback);

        const checkCompletion = () => {
          const source = mapRef.getSource(pathId);
          if (
            source &&
            source._data.geometry.coordinates.length === path.length
          ) {
            markerCallback();
            resolve();
          } else {
            setTimeout(checkCompletion, 100);
          }
        };
        checkCompletion();
      });
    };

    // 2. Handle TFT paths
    if (
      TFT !== null &&
      TFT === true &&
      !TF &&
      TF !== null &&
      FT !== null &&
      !FT
    ) {
      const pathTFT1 = [
        ...createArc(
          sourceLocation.coordinates,
          intermediate1Location.coordinates
        ),
        ...createArc(
          intermediate1Location.coordinates,
          intermediateD1Location.coordinates
        ),
        ...createArc(
          intermediateD1Location.coordinates,
          destinationLocation.coordinates
        ),
      ];

      const pathTFT2 = [
        ...createArc(
          sourceLocation.coordinates,
          intermediate2Location.coordinates
        ),
        ...createArc(
          intermediate2Location.coordinates,
          intermediateD2Location.coordinates
        ),
        ...createArc(
          intermediateD2Location.coordinates,
          destinationLocation.coordinates
        ),
      ];

      // Add sources and layers for TFT paths
      ["pathTFT1", "pathTFT2"].forEach((pathId) => {
        mapRef.addSource(pathId, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [],
            },
          },
        });

        mapRef.addLayer({
          id: pathId,
          type: "line",
          source: pathId,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#833AB4",
            "line-width": 3,
            "line-opacity": 1,
          },
        });
      });

      // Animate TFT paths sequentially
      await createAnimationPromise(
        mapRef,
        pathTFT1,
        "pathTFT1",
        intermediate1Location.coordinates,
        () => {
          addMarkerWithLabel(
            mapRef,
            intermediate1Location.coordinates,
            intermediate1Location.name,
            "intermediate1",
            true
          );
          addMarkerWithLabel(
            mapRef,
            intermediateD1Location.coordinates,
            intermediateD1Location.name,
            "intermediateD1",
            true
          );
        }
      );

      await createAnimationPromise(
        mapRef,
        pathTFT2,
        "pathTFT2",
        intermediate2Location.coordinates,
        () => {
          addMarkerWithLabel(
            mapRef,
            intermediate2Location.coordinates,
            intermediate2Location.name,
            "intermediate2",
            true
          );
          addMarkerWithLabel(
            mapRef,
            intermediateD2Location.coordinates,
            intermediateD2Location.name,
            "intermediateD2",
            true
          );
        }
      );
    }

    // 3. Handle TF and FT paths
    if (TF || FT) {
      if (TF && FT) {
        // Create all paths
        const pathTF1 = [
          ...createArc(
            sourceLocation.coordinates,
            intermediate1Location.coordinates
          ),
          ...createArc(
            intermediate1Location.coordinates,
            destinationLocation.coordinates
          ),
        ];
        const pathTF2 = [
          ...createArc(
            sourceLocation.coordinates,
            intermediate2Location.coordinates
          ),
          ...createArc(
            intermediate2Location.coordinates,
            destinationLocation.coordinates
          ),
        ];
        const pathFT1 = [
          ...createArc(
            sourceLocation.coordinates,
            intermediateD1Location.coordinates
          ),
          ...createArc(
            intermediateD1Location.coordinates,
            destinationLocation.coordinates
          ),
        ];
        const pathFT2 = [
          ...createArc(
            sourceLocation.coordinates,
            intermediateD2Location.coordinates
          ),
          ...createArc(
            intermediateD2Location.coordinates,
            destinationLocation.coordinates
          ),
        ];

        // Add sources and layers for all paths
        ["pathTF1", "pathTF2", "pathFT1", "pathFT2"].forEach((pathId) => {
          mapRef.addSource(pathId, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [],
              },
            },
          });

          mapRef.addLayer({
            id: pathId,
            type: "line",
            source: pathId,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": pathId.startsWith("pathTF") ? "#0047f4" : "#EF00D2",
              "line-width": 3,
              "line-opacity": 1,
            },
          });
        });

        // Animate paths sequentially with completion check
        await createAnimationPromise(
          mapRef,
          pathTF1,
          "pathTF1",
          intermediate1Location.coordinates,
          () => {
            addMarkerWithLabel(
              mapRef,
              intermediate1Location.coordinates,
              intermediate1Location.name,
              "intermediate1",
              true
            );
          }
        );

        await createAnimationPromise(
          mapRef,
          pathTF2,
          "pathTF2",
          intermediate2Location.coordinates,
          () => {
            addMarkerWithLabel(
              mapRef,
              intermediate2Location.coordinates,
              intermediate2Location.name,
              "intermediate2",
              true
            );
          }
        );

        await createAnimationPromise(
          mapRef,
          pathFT1,
          "pathFT1",
          intermediateD1Location.coordinates,
          () => {
            addMarkerWithLabel(
              mapRef,
              intermediateD1Location.coordinates,
              intermediateD1Location.name,
              "intermediateD1",
              true
            );
          }
        );

        await createAnimationPromise(
          mapRef,
          pathFT2,
          "pathFT2",
          intermediateD2Location.coordinates,
          () => {
            addMarkerWithLabel(
              mapRef,
              intermediateD2Location.coordinates,
              intermediateD2Location.name,
              "intermediateD2",
              true
            );
          }
        );
      } else if (TF) {
        // Handle TF only paths
        const pathTF1 = [
          ...createArc(
            sourceLocation.coordinates,
            intermediate1Location.coordinates
          ),
          ...createArc(
            intermediate1Location.coordinates,
            destinationLocation.coordinates
          ),
        ];
        const pathTF2 = [
          ...createArc(
            sourceLocation.coordinates,
            intermediate2Location.coordinates
          ),
          ...createArc(
            intermediate2Location.coordinates,
            destinationLocation.coordinates
          ),
        ];

        ["pathTF1", "pathTF2"].forEach((pathId) => {
          mapRef.addSource(pathId, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [],
              },
            },
          });

          mapRef.addLayer({
            id: pathId,
            type: "line",
            source: pathId,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#0047f4",
              "line-width": 3,
              "line-opacity": 1,
            },
          });
        });

        await createAnimationPromise(
          mapRef,
          pathTF1,
          "pathTF1",
          intermediate1Location.coordinates,
          () => {
            addMarkerWithLabel(
              mapRef,
              intermediate1Location.coordinates,
              intermediate1Location.name,
              "intermediate1",
              true
            );
          }
        );

        await createAnimationPromise(
          mapRef,
          pathTF2,
          "pathTF2",
          intermediate2Location.coordinates,
          () => {
            addMarkerWithLabel(
              mapRef,
              intermediate2Location.coordinates,
              intermediate2Location.name,
              "intermediate2",
              true
            );
          }
        );
      } else if (FT) {
        // Handle FT only paths
        const pathFT1 = [
          ...createArc(
            sourceLocation.coordinates,
            intermediateD1Location.coordinates
          ),
          ...createArc(
            intermediateD1Location.coordinates,
            destinationLocation.coordinates
          ),
        ];
        const pathFT2 = [
          ...createArc(
            sourceLocation.coordinates,
            intermediateD2Location.coordinates
          ),
          ...createArc(
            intermediateD2Location.coordinates,
            destinationLocation.coordinates
          ),
        ];

        ["pathFT1", "pathFT2"].forEach((pathId) => {
          mapRef.addSource(pathId, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [],
              },
            },
          });

          mapRef.addLayer({
            id: pathId,
            type: "line",
            source: pathId,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#EF00D2",
              "line-width": 3,
              "line-opacity": 1,
            },
          });
        });

        await createAnimationPromise(
          mapRef,
          pathFT1,
          "pathFT1",
          intermediateD1Location.coordinates,
          () => {
            addMarkerWithLabel(
              mapRef,
              intermediateD1Location.coordinates,
              intermediateD1Location.name,
              "intermediateD1",
              true
            );
          }
        );

        await createAnimationPromise(
          mapRef,
          pathFT2,
          "pathFT2",
          intermediateD2Location.coordinates,
          () => {
            addMarkerWithLabel(
              mapRef,
              intermediateD2Location.coordinates,
              intermediateD2Location.name,
              "intermediateD2",
              true
            );
          }
        );
      }
    }
  };

  useEffect(() => {
    if (!mapRef && mapContainerRef.current) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: Styler,
        center: INITIAL_VIEW.center,
        globe: {
          place_label_major: false,
          place_label_minor: false,
          place_label_other: false,
        },
        zoom: INITIAL_VIEW.zoom,
        pitch: INITIAL_VIEW.pitch,
        bearing: INITIAL_VIEW.bearing,
        projection: "globe",
        dragRotate: false, // Disable rotation
        interactive: false,
      });

      // Add fog and terrain configuration
      map.on("style.load", () => {
        // Configure fog effect
        map.setFog({
          color: "rgb(180, 180, 180)", // Day sky color
          "high-color": "rgb(180, 180, 180)", // Blue atmosphere
          "horizon-blend": 0.02, // Atmosphere thickness
          "space-color": "rgb(256, 256, 256)", // Dark space
          "star-intensity": 0.6, // Brightness of stars
        });

        // Add terrain if available in the style
        if (map.getSource("mapbox-dem")) {
          map.setTerrain({
            source: "mapbox-dem",
            exaggeration: 1.5, // Terrain exaggeration
          });
        }
      });

      // Add quality improvement handlers
      map.on("movestart", () => {
        map.getCanvas().style.imageRendering = "high-quality";
      });

      map.on("moveend", () => {
        map.getCanvas().style.imageRendering = "auto";
      });

      setMapRef(map);
    }
  }, [mapContainerRef]);

  useEffect(() => {
    if (!mapContainerRef.current) return;

    if (mapRef) {
      // Add this after map initialization
      mapRef.on("style.load", () => {
        const layersToHide = [
          "settlement-major-label", // Major cities
          "settlement-minor-label", // Minor cities
          "settlement-subdivision-label", // Neighborhoods
        ];

        layersToHide.forEach((layer) => {
          if (mapRef.getLayer(layer)) {
            mapRef.setLayoutProperty(layer, "visibility", "none");
          }
        });
      });

      if (hasValidProps()) {
        initializeAnimationSequence();
      }
    }
  }, [
    sourceLocation,
    destinationLocation,
    intermediate1Location,
    intermediate2Location,
    mapRef,
  ]);

  useEffect(() => {
    if (mapanimatestart) {
      const timer = setTimeout(() => {
        if (dateWiseData && Object.keys(dateWiseData).length > 0) {
          // setShowMap(false);
          dispatch(setMapanimatestart(false));
        }
      }, 8000);
      return () => clearTimeout(timer);
    } else if (dateWiseData && Object.keys(dateWiseData).length > 0) {
      dispatch(setMapanimatestart(false));
      // setShowMap(false);
    }
  }, [mapanimatestart, dateWiseData]);

  return (
    <>
      {children}
      {(location.pathname == "/" ||
        (location.pathname == "/modes" && mapanimatestart)) && (
        <>
          <div style={{ height: "calc(100vh - 132px)" }}></div>
          <div
            className={`map-container right-0 ${
              location.pathname == "/modes"
                ? "fixed top-[136px]"
                : "absolute bottom-0"
            }`}
          >
            <div
              ref={mapContainerRef}
              style={{ height: "calc(100vh - 132px)" }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default MapComponent;
