import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { ReactComponent as Plane } from "../assets/images/flight.png";
import info from "../../assets/vectors/bx-info-circle.svg";
import People from "../../assets/vectors/Vector75_x2.svg";
import Buffer from "../../assets/vectors/BxInfoCircle9_x2.svg";
import FlightIndividual from "../cards/flightcard";
import FlightFlightcard from "../cards/flightFlightcard";
import UpArrow from "../../assets/images/up_arrow.svg";
import DownArrow from "../../assets/images/down_arrow.svg";
import Plane_V from "../../assets/vectors1/Plane_V.svg";
import AkasaAir from "../../assets/flightnormal/akasaair.png";
import Star_air from "../../assets/images/startAir.png";

import IndigoS from "../../assets/flightsmall/indigo.png";
import AirIndiaS from "../../assets/flightsmall/airindia.png";
import AirIndiaExpressS from "../../assets/flightsmall/airindiaexpress.png";
import VistaraS from "../../assets/flightsmall/vistara.png";
import SpicejetS from "../../assets/flightsmall/spicejet.png";
import AkasaAirS from "../../assets/flightsmall/akasaair.png";
import StarAirS from "../../assets/flightsmall/starair.png";

import Indigo from "../../assets/flightnormal/indigo.png";
import AirIndia from "../../assets/flightnormal/airindia.png";
import AirIndiaExpress from "../../assets/flightnormal/airindiaexpress.png";
import Vistara from "../../assets/flightnormal/vistara.png";
import Spicejet from "../../assets/flightnormal/spicejet.png";
import {
  IndirectFlightModel,
  DirectFlightModel,
} from "../../utils/models_types";
import { RootState } from "../../store/store";

export default function FlightCard({
  flight,
}: {
  flight: IndirectFlightModel | DirectFlightModel;
}) {
  const adults = useSelector<RootState, number>((state) => state.data.adults);

  const convertDurationToHours = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };
  const [closecard, setClosecard] = useState<boolean>(false);

  const dateFormat = new Intl.DateTimeFormat("en-GB", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  });

  const formatDate = (inputdate: number): string => {
    return dateFormat.format(new Date(inputdate - 5.5 * 60 * 60));
  };

  const timeFormat = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formatTime = (inputtime: number): string => {
    return timeFormat.format(new Date(inputtime));
  };

  const imageselector = (inputcomp: string): string | undefined => {
    if (inputcomp === "indiGo") {
      return Indigo;
    } else if (inputcomp === "vistara") {
      return Vistara;
    } else if (inputcomp === "air india express") {
      return AirIndiaExpress;
    } else if (inputcomp === "air india") {
      return AirIndia;
    } else if (inputcomp === "akasa air") {
      return AkasaAir;
    } else if (inputcomp === "star air") {
      return Star_air;
    } else if (inputcomp === "spicejet") {
      return Spicejet;
    }
  };

  const imageselectorS = (inputcomp: string): string | undefined => {
    if (inputcomp === "indigo") {
      return IndigoS;
    } else if (inputcomp === "vistara") {
      return VistaraS;
    } else if (inputcomp === "air india express") {
      return AirIndiaExpressS;
    } else if (inputcomp === "air india") {
      return AirIndiaS;
    } else if (inputcomp === "akasa air") {
      return AkasaAirS;
    } else if (inputcomp === "starair") {
      return StarAirS;
    } else if (inputcomp === "spicejet") {
      return SpicejetS;
    }
  };
  const flightname = (inputcomp: string): string | undefined => {
    if (inputcomp === "IndiGo") {
      return "6E";
    } else if (inputcomp === "Vistara") {
      return "UK";
    } else if (inputcomp === "Air India Express") {
      return "IX";
    } else if (inputcomp === "Air India") {
      return "AI";
    } else if (inputcomp === "Akasa Air") {
      return "QP";
    } else if (inputcomp === "Start Air") {
      return "S5";
    }
  };

  return (
    <>
      {!flight.properties.stop_count ? (
        <>
          <div>
            <FlightIndividual
              flightComponent={
                "segments" in flight.properties
                  ? flight.properties.segments[0]
                  : flight.properties
              }
            />

            <div className="card-bottom">
              <div className="bottom-duration">
                <p>
                  Journey Duration :{" "}
                  <span className="text-[#F1C40F] font-regular">
                    {convertDurationToHours(
                      "segments" in flight.properties
                        ? flight.properties.segments[0].duration
                        : flight.properties.duration
                    )}
                  </span>
                </p>
              </div>
              <div className="card-price">
                <p>
                  <span>Total Price :</span>
                  <span className="text-[#F1C40F] font-regular">
                    ₹{flight.properties.price}
                  </span>
                </p>
                <img className="text-[#F1C40F]" src={info} />
              </div>

              <button
                // disabled={!selectedPrice}
                // disabled:saturate-0
                className="card-book-btn  disabled:cursor-not-allowed"
              >
                Select
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <FlightFlightcard
              ffCompenent={(flight as IndirectFlightModel).properties}
            />

            <div className="card-bottom">
              <div className="bottom-duration">
                <p>
                  Journey Duration :{" "}
                  <span className="text-[#F1C40F] font-regular">
                    {convertDurationToHours(
                      "segments" in flight.properties
                        ? flight.properties.segments[0].duration
                        : flight.properties.duration
                    )}
                  </span>
                </p>
              </div>
              <div className="card-price">
                <p>
                  <span>Total Price :</span>
                  <span className="text-[#F1C40F] font-regular">
                    ₹{flight.properties.price}
                  </span>
                </p>
                <img className="text-[#F1C40F]" src={info} />
              </div>

              <button
                // disabled={!selectedPrice}
                // disabled:saturate-0
                className="card-book-btn  disabled:cursor-not-allowed"
              >
                Select
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
