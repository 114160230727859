import "./index.css";
import Router from "./Routes/Router";
import React from "react";

export default function App() {
  const [authenticated, setAuthenticated] = React.useState(false);
  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (!authenticated) {
        const password = prompt(
          "Please enter the password to access the website:"
        );
        if (password === "zxc") {
          setAuthenticated(true);
        } else {
          alert("Incorrect password! Access denied.");
          window.location.reload();
        }
      }
    }
  }, []); // Remove authenticated from dependencies since we only want this to run once

  if (!authenticated) {
    return null;
  }
  return (
    <div>
      <Router />
    </div>
  );
}
