import { MeiliSearch } from "meilisearch";

const client = new MeiliSearch({
  host: process.env.REACT_APP_RECOMMENDER_HOST ?? "",
  apiKey: process.env.REACT_APP_MASTER_KEY,
});

export const searchDocuments = async (query: string) => {
  const index = client.index(process.env.REACT_APP_RECOMMENDER_INDEX ?? "");
  const searchResults = await index.search(query);
  return searchResults;
};
