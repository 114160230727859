import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./ModesPage.css";
import { useSelector, useDispatch } from "react-redux";
import { searchDocuments } from "../utils/elastic_recommender";
import SearchBar from "../components/commons/Searchbar";
import {
  // setUpdatedData,
  setFromCity,
  setFromState,
  setToCity,
  setToState,
  setAdults,
  emptyDateWiseData,
  setDepartureDate,
  setData,
  dateWiseObject,
} from "../store/dataSlice";
import { RootState } from "../store/store";

import ModeCard from "../components/commons/modecards";

import { formatDay, convertToMinutes } from "../utils/misc";
import {
  BackendResult,
  DirectFlightModel,
  FlightTrainModel,
  IndirectFlightModel,
  Train,
  TrainFlightTrainModel,
  TrainModel,
  TrainTrainModel,
} from "../utils/models_types";

//TODO: add to utils
// Debounce function
const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

//TODO: add to utils
const priorityList = ["SL", "CC", "3A", "2A", "1A"];

//TODO: add to utils
export function getAvailableClass(classesWithPrices: {
  [key: string]: number;
}) {
  for (let classType of priorityList) {
    if (classesWithPrices[classType] !== undefined) {
      return classesWithPrices[classType];
    }
  }

  return null;
}

//TODO: add to utils
export function getAvailableClassType(classesWithPrices: {
  [key: string]: number;
}) {
  for (let classType of priorityList) {
    if (classesWithPrices[classType] !== undefined) {
      return classType;
    }
  }

  return null;
}

const ModesPage = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const from = searchParams.get("from");
  const to = searchParams.get("to");
  const statefrom = searchParams.get("statefrom");
  const stateto = searchParams.get("stateto");
  const date = searchParams.get("date");
  const no = (searchParams.get("no") ?? 0) as number;

  const fromCity = useSelector<RootState, string>(
    (state) => state.data.fromCity
  );
  const fromState = useSelector<RootState, string>(
    (state) => state.data.fromState
  );
  const toCity = useSelector<RootState, string>((state) => state.data.toCity);
  const toState = useSelector<RootState, string>((state) => state.data.toState);
  const departureDate = useSelector<RootState, string>(
    (state) => state.data.departureDate
  );
  const adults = useSelector<RootState, number>((state) => state.data.adults);
  const dateWiseData = useSelector<RootState, dateWiseObject>(
    (state) => state.data.dateWiseData
  );

  const flightData = dateWiseData[departureDate]?.flightData ?? [];
  const trainData = dateWiseData[departureDate]?.trainData ?? [];

  const trainFlightIntermediateObject =
    dateWiseData[departureDate]?.trainFlightIntermediateObject ?? {};
  const flightTrainIntermediateObject =
    dateWiseData[departureDate]?.flightTrainIntermediateObject ?? {};
  const trainFlightTrainIntermediateObject =
    dateWiseData[departureDate]?.trainFlightTrainIntermediateObject ?? {};
  const trainTrainIntermediateObject =
    dateWiseData[departureDate]?.trainTrainIntermediateObject ?? {};

  const [loading, setLoading] = useState(false);

  const [tempFromCity, setTempFromCity] = useState(from!);
  const [tempFromState, setTempFromState] = useState(statefrom!);
  const [tempToCity, setTempToCity] = useState(to!);
  const [tempToState, setTempToState] = useState(stateto!);
  const [tempDepartureDate, setTempDepartureDate] = useState(date!);
  const [tempAdults, setTempAdults] = useState(no!);
  const [initialTotal, setInitialTotal] = useState(0);

  const handleSearch = async () => {
    dispatch(emptyDateWiseData());
    dispatch(setFromCity(tempFromCity));
    dispatch(setFromState(tempFromState));
    dispatch(setToCity(tempToCity));
    dispatch(setToState(tempToState));
    dispatch(setDepartureDate(tempDepartureDate));
    dispatch(setAdults(tempAdults));
    if (
      tempFromCity !== from ||
      tempFromState !== statefrom ||
      tempToCity !== to ||
      tempToState !== stateto ||
      tempDepartureDate !== date ||
      tempAdults !== no
    ) {
      console.log(" !!! updating the url");
      setSearchParams({
        from: tempFromCity?.toLowerCase(),
        statefrom: tempFromState?.toLowerCase(),
        to: tempToCity?.toLowerCase(),
        stateto: tempToState?.toLowerCase(),
        date: tempDepartureDate,
        no: tempAdults.toString(),
      });
    }

    if (dummymode) {
      updateDetails({});
      return;
    }

    if (
      !tempFromCity ||
      !tempToCity ||
      !tempToCity ||
      !tempToState ||
      !tempAdults ||
      !tempDepartureDate
    ) {
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/test?source_city=${tempFromCity}&desti_city=${tempToCity}&source_state=${tempFromState}&desti_state=${tempToState}&departure_date=${tempDepartureDate}&adults=${tempAdults}`
        // `http://13.233.103.116:4000/routes?source_city=${fromCity}&desti_city=${toCity}&departure_date=${departureDate}&adults=${adults}`
        //   , {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(payload),
        // }
      );

      if (!response.ok) {
        setLoading(false);

        throw new Error("Network response was not ok");
      }
      setLoading(false);

      const data = await response.json();
      console.log("API response:", data);

      setInitialTotal(
        Number(data?.results?.direct_train?.length > 0) +
          Number(data?.results?.direct_flight?.length > 0) +
          Number(data?.results?.train_flight?.length > 0) +
          Number(data?.results?.train_flight_train?.length > 0) +
          Number(data?.results?.flight_train?.length > 0) +
          Number(data?.results?.train_train?.length > 0)
      );

      await updateDetails(data?.results);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      await updateDetails({});
      setLoading(false);
    }
  };

  const handleViewDetails = (
    mode: string,
    intermediate = "none",
    minDateData:
      | TrainModel
      | TrainTrainModel
      | FlightTrainModel
      | TrainFlightTrainModel
      | (DirectFlightModel | IndirectFlightModel)
  ) => {
    console.log(intermediate);
    navigate("/details", {
      state: { mode, intermediate, minDateData },
    });
  };

  const isSearchDisabled =
    !tempFromCity || !tempToCity || !tempFromState || !tempToState;

  const dummymode = false;

  async function updateDetails(data: BackendResult) {
    dispatch(
      setData({
        type: "flight",
        data: dummymode
          ? [
              {
                type: "path",
                mode: "flight",
                properties: {
                  stop_count: 1,
                  segments: [
                    {
                      origin: {
                        id: "none",
                        name: "Chhatrapati Shivaji Maharaj International Airport",
                        display_code: "BOM",
                        city: "Mumbai",
                        terminal: "2",
                      },
                      destination: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      departure_time: 1724370900000,
                      arrival_time: 1724378100000,
                      duration: 120,
                      flight_name: "StarAir",
                      flight_number: "518",
                      logo_url: "none",
                      layover_time: 0,
                    },
                    {
                      origin: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      destination: {
                        id: "none",
                        name: "Indira Gandhi Airport",
                        display_code: "DEL",
                        city: "Delhi",
                        terminal: "3",
                      },
                      departure_time: 1724426400000,
                      arrival_time: 1724439000000,
                      duration: 210,
                      flight_name: "Indigo",
                      flight_number: "832",
                      logo_url: "none",
                      layover_time: 805,
                    },
                  ],
                  total_duration: 1135,
                  price: 166299,
                },
              },
              {
                type: "path",
                mode: "flight",
                properties: {
                  origin: {
                    id: "none",
                    name: "Nanded",
                    display_code: "NDC",
                    city: "Nanded",
                  },
                  destination: {
                    id: "none",
                    name: "Hindon Airport",
                    display_code: "HDO",
                    city: "Ghaziabad",
                  },
                  departure_time: 1725617700000,
                  arrival_time: 1725624900000,
                  duration: 120,
                  flight_name: "StarAir",
                  flight_number: "187",
                  stop_count: 0,
                  price: 5924,
                  tags: "none",
                  logo_url: "none",
                  alternate_id: "none",
                },
              },
              {
                type: "path",
                mode: "flight",
                properties: {
                  stop_count: 1,
                  segments: [
                    {
                      origin: {
                        id: "none",
                        name: "Chhatrapati Shivaji Maharaj International Airport",
                        display_code: "BOM",
                        city: "Mumbai",
                        terminal: "2",
                      },
                      destination: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      departure_time: 1724370900000,
                      arrival_time: 1724378100000,
                      duration: 120,
                      flight_name: "Air India",
                      flight_number: "518",
                      logo_url: "none",
                      layover_time: 0,
                    },
                    {
                      origin: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      destination: {
                        id: "none",
                        name: "Indira Gandhi Airport",
                        display_code: "DEL",
                        city: "Delhi",
                        terminal: "3",
                      },
                      departure_time: 1724426400000,
                      arrival_time: 1724439000000,
                      duration: 210,
                      flight_name: "Akasa Air",
                      flight_number: "832",
                      logo_url: "none",
                      layover_time: 805,
                    },
                  ],
                  total_duration: 1135,
                  price: 166299,
                },
              },
              {
                type: "path",
                mode: "flight",
                properties: {
                  stop_count: 1,
                  segments: [
                    {
                      origin: {
                        id: "none",
                        name: "Chhatrapati Shivaji Maharaj International Airport",
                        display_code: "BOM",
                        city: "Mumbai",
                        terminal: "2",
                      },
                      destination: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      departure_time: 1724370900000,
                      arrival_time: 1724378100000,
                      duration: 120,
                      flight_name: "Air India Express",
                      flight_number: "518",
                      logo_url: "none",
                      layover_time: 0,
                    },
                    {
                      origin: {
                        id: "none",
                        name: "Kochi",
                        display_code: "COK",
                        city: "Kochi",
                        terminal: "1",
                      },
                      destination: {
                        id: "none",
                        name: "Indira Gandhi Airport",
                        display_code: "DEL",
                        city: "Delhi",
                        terminal: "3",
                      },
                      departure_time: 1724393100000,
                      arrival_time: 1724405100000,
                      duration: 200,
                      flight_name: "Vistara",
                      flight_number: "527",
                      logo_url: "none",
                      layover_time: 250,
                    },
                  ],
                  total_duration: 570,
                  price: 233244,
                },
              },
            ]
          : await withFlightNames(data?.direct_flight ?? []),
      })
    );
    dispatch(
      setData({
        type: "train",
        data: dummymode
          ? [
              {
                type: "path",
                mode: "train",
                properties: {
                  station_from: "J",
                  station_to: "SNSI",
                  departure_time: 1723606320000,
                  arrival_time: 1723626600000,
                  duration: "5:38",
                  train_number: "17208",
                  train_name:
                    "Machilipatnam - Sainagar Shirdi Express vksavckavcuyVCOUAYVcOUVYCHBVOUYVCDSO",
                  from_day_count: 0,
                  to_day_count: 0,
                  class_type: ["SL", "3A", "2A", "1A"],
                },
              },
            ]
          : await withStationNames(data?.direct_train ?? []),
      })
    );
    dispatch(
      setData({
        type: "trainTrain",
        data: dummymode
          ? [
              {
                intermediate: "PURNA",
                train1: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "NED",
                    station_to: "PAU",
                    departure_time: 1724302800000,
                    arrival_time: 1724304300000,
                    duration: "0:25",
                    train_number: "17663",
                    train_name: "RC PBN EXPRESS",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A"],
                  },
                },
                train2: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "PAU",
                    station_to: "CSMT",
                    departure_time: 1724323080000,
                    arrival_time: 1724364480000,
                    duration: "11:30",
                    train_number: "17618",
                    train_name: "Tapovan Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "CC"],
                  },
                },
              },
              {
                intermediate: "PARBHANI",
                train1: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "NED",
                    station_to: "PBN",
                    departure_time: 1724288100000,
                    arrival_time: 1724292480000,
                    duration: "1:13",
                    train_number: "12787",
                    train_name: "NARASAPUR - NAGARSOL SF Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                  },
                },
                train2: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "PBN",
                    station_to: "PNVL",
                    departure_time: 1724354400000,
                    arrival_time: 1724402100000,
                    duration: "13:15",
                    train_number: "17614",
                    train_name: "Hazur Sahib Nanded - Panvel Express",
                    from_day_count: 0,
                    to_day_count: 1,
                    class_type: ["SL", "3A", "2A", "1A"],
                  },
                },
              },
            ]
          : await modifyTTData(data?.train_train ?? []),
      })
    );
    dispatch(
      setData({
        type: "flightTrain",
        data: dummymode
          ? [
              {
                intermediate: "NASHIK",
                train: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "NK",
                    departure_time: 1723599300000,
                    arrival_time: 1723615920000,
                    duration: "4:37",
                    train_number: "17611",
                    train_name: "Rajya Rani Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "SL", "CC", "3A", "2A", "1A"],
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Ozar Airport",
                      display_code: "ISK",
                      city: "Nasik",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723627200000,
                    arrival_time: 1723634100000,
                    duration: 115,
                    flight_name: "Indigo",
                    flight_number: "2037",
                    stop_count: 0,
                    price: 6688,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
              {
                intermediate: "SHIRDI",
                train: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "SNSI",
                    departure_time: 1723606320000,
                    arrival_time: 1723626600000,
                    duration: "5:38",
                    train_number: "17208",
                    train_name: "Machilipatnam - Sainagar Shirdi Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Shirdi airport",
                      display_code: "SAG",
                      city: "Shirdi",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723656300000,
                    arrival_time: 1723663800000,
                    duration: 125,
                    flight_name: "SpiceJet",
                    flight_number: "8947",
                    stop_count: 0,
                    price: 4743,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
            ]
          : await modifyTFData(data?.flight_train ?? [], "flightTrain"),
      })
    );
    dispatch(
      setData({
        type: "trainFlight",
        data: dummymode
          ? [
              {
                intermediate: "NASHIK",
                train: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "NK",
                    departure_time: 1723599300000,
                    arrival_time: 1723615920000,
                    duration: "4:37",
                    train_number: "17611",
                    train_name: "Rajya Rani Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "SL", "CC", "3A", "2A", "1A"],
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Ozar Airport",
                      display_code: "ISK",
                      city: "Nasik",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723627200000,
                    arrival_time: 1723634100000,
                    duration: 115,
                    flight_name: "Indigo",
                    flight_number: "2037",
                    stop_count: 0,
                    price: 6688,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
              {
                intermediate: "SHIRDI",
                train: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "SNSI",
                    departure_time: 1723606320000,
                    arrival_time: 1723626600000,
                    duration: "5:38",
                    train_number: "17208",
                    train_name: "Machilipatnam - Sainagar Shirdi Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Shirdi airport",
                      display_code: "SAG",
                      city: "Shirdi",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723656300000,
                    arrival_time: 1723663800000,
                    duration: 125,
                    flight_name: "SpiceJet",
                    flight_number: "8947",
                    stop_count: 0,
                    price: 4743,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
            ]
          : await modifyTFData(data?.train_flight ?? [], "trainFlight"),
      })
    );
    dispatch(
      setData({
        type: "trainFlightTrain",
        data: dummymode
          ? [
              {
                intermediate1: "NASHIK",
                intermediate2: "NASHIK2",
                train1: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "NK",
                    departure_time: 1723599300000,
                    arrival_time: 1723615920000,
                    duration: "4:37",
                    train_number: "17611",
                    train_name: "Rajya Rani Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "SL", "CC", "3A", "2A", "1A"],
                  },
                },
                train2: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "SNSI",
                    departure_time: 1723606320000,
                    arrival_time: 1723626600000,
                    duration: "5:38",
                    train_number: "17208",
                    train_name: "Machilipatnam - Sainagar Shirdi Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Ozar Airport",
                      display_code: "ISK",
                      city: "Nasik",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723627200000,
                    arrival_time: 1723634100000,
                    duration: 115,
                    flight_name: "Indigo",
                    flight_number: "2037",
                    stop_count: 0,
                    price: 6688,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
              {
                intermediate1: "SHIRDI",
                intermediate2: "SHIRDI2",
                train1: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "SNSI",
                    departure_time: 1723606320000,
                    arrival_time: 1723626600000,
                    duration: "5:38",
                    train_number: "17208",
                    train_name: "Machilipatnam - Sainagar Shirdi Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["SL", "3A", "2A", "1A"],
                  },
                },
                train2: {
                  type: "path",
                  mode: "train",
                  properties: {
                    station_from: "J",
                    station_to: "NK",
                    departure_time: 1723599300000,
                    arrival_time: 1723615920000,
                    duration: "4:37",
                    train_number: "17611",
                    train_name: "Rajya Rani Express",
                    from_day_count: 0,
                    to_day_count: 0,
                    class_type: ["2S", "SL", "CC", "3A", "2A", "1A"],
                  },
                },
                flight: {
                  type: "path",
                  mode: "flight",
                  properties: {
                    origin: {
                      id: "none",
                      name: "Shirdi airport",
                      display_code: "SAG",
                      city: "Shirdi",
                    },
                    destination: {
                      id: "none",
                      name: "Indira Gandhi Airport",
                      display_code: "DEL",
                      city: "Delhi",
                    },
                    departure_time: 1723656300000,
                    arrival_time: 1723663800000,
                    duration: 125,
                    flight_name: "SpiceJet",
                    flight_number: "8947",
                    stop_count: 0,
                    price: 4743,
                    tags: "none",
                    logo_url: "none",
                    alternate_id: "none",
                  },
                },
              },
            ]
          : await modifyTFTData(
              data?.train_flight_train ?? [],
              "trainFlightTrain"
            ),
      })
    );
  }

  const initialized = useRef(false);

  useEffect(() => {
    console.log(dummymode);
    if (
      !dummymode &&
      (!from || !statefrom || !to || !stateto || !date || !no)
    ) {
      alert("Invalid URL, Redirecting to Home Page");
    }

    // const searched = sessionStorage.getItem("searched");
    if (!initialized.current) {
      initialized.current = true;
      if (
        // searched === "true" ||
        dummymode ||
        fromCity !== from ||
        fromState !== statefrom ||
        toCity !== to ||
        toState !== stateto ||
        departureDate !== date ||
        adults !== no
      ) {
        console.log(
          fromCity,
          from,
          fromState,
          statefrom,
          toCity,
          to,
          toState,
          stateto,
          departureDate,
          date,
          adults,
          no
        );
        console.log("updating data");
        handleSearch();
        // sessionStorage.setItem("searched", "false");
      } else {
        console.log("Same inputs so no need to update");
      }
    }
  }, [from, to, date, no, statefrom, stateto]);

  useEffect(() => {
    const searched = sessionStorage.getItem("searched");
    if (searched === "true") {
      console.log("searched");
      window.scrollTo(0, 100);
    }
  }, []);

  const totalPathsCount = () => {
    return (
      Number(trainData?.length > 0) +
      Number(flightData?.length > 0) +
      Object.keys(flightTrainIntermediateObject).length +
      Object.keys(trainFlightIntermediateObject).length +
      Object.keys(trainTrainIntermediateObject).length +
      Object.keys(trainFlightTrainIntermediateObject).length
    );
  };

  async function withFlightNames(
    flightData: (DirectFlightModel | IndirectFlightModel)[]
  ): Promise<(DirectFlightModel | IndirectFlightModel)[]> {
    console.log(flightData);
    if (
      !flightData ||
      flightData.length === 0 ||
      (flightData[0] && "train" in flightData[0])
    ) {
      console.log("No data available for flight.");
      return []; // Early exit if flightData is undefined or null
    }

    const newData = await Promise.all(
      flightData.map(async (combination, i) => {
        if (!combination.properties) {
          console.log(combination);
        }
        if (combination.properties && "segments" in combination.properties) {
          const newsegments = await Promise.all(
            combination.properties.segments.map(async (segment, si) => {
              const airportNames = await getAirportNames(
                segment.origin.display_code,
                segment.destination.display_code
              );

              return {
                ...segment,
                origin: {
                  ...segment.origin,
                  name: airportNames.origin_airport_name,
                },
                destination: {
                  ...segment.destination,
                  name: airportNames.destination_airport_name,
                },
                origin_city: airportNames.origin_city,
                destination_city: airportNames.destination_city,
              };
            })
          );
          const flightPriceInNumber = combination.properties.price;

          return {
            ...combination,
            properties: {
              ...combination.properties,
              segments: newsegments,
            },
            total_duration: combination.properties.total_duration,
            total_price: flightPriceInNumber,
          };
        } else {
          const airportNames = await getAirportNames(
            combination.properties.origin.display_code,
            combination.properties.destination.display_code
          );

          const flightPriceInNumber = combination.properties.price;

          return {
            ...combination,
            properties: {
              ...combination.properties,
              origin: {
                ...combination.properties.origin,
                name: airportNames.origin_airport_name,
              },
              destination: {
                ...combination.properties.destination,
                name: airportNames.destination_airport_name,
              },
              origin_city: airportNames.origin_city,
              destination_city: airportNames.destination_city,
            },
            total_duration: combination.properties.duration,
            total_price: flightPriceInNumber,
          };
        }
      })
    );
    console.log(newData);

    return newData;
  }

  async function withStationNames(
    trainData: TrainModel[]
  ): Promise<TrainModel[]> {
    console.log(trainData);
    if (
      !trainData ||
      trainData.length === 0 ||
      (trainData[0] && "flight" in trainData[0])
    ) {
      console.log("No data available for flight.");
      return []; // Early exit if trainData is undefined or null
    }

    const newData = await Promise.all(
      trainData.map(async (combination, i) => {
        console.log(combination);

        const stationNames = await getStationNames(
          combination.properties.station_from,
          combination.properties.station_to
        );

        // const prices = await getFares(
        //   combination.properties.train_number,
        //   combination.properties.station_from,
        //   combination.properties.station_to
        // );
        // const trainFareInNumber = getAvailableClass(prices.general) || 0;
        const trainFareInNumber = 0;

        console.log(trainFareInNumber);

        return {
          ...combination,
          properties: {
            ...combination.properties,
            from_station_name: stationNames.origin_station_name,
            to_station_name: stationNames.destination_station_name,
            origin_city: stationNames.origin_city,
            destination_city: stationNames.destination_city,
            // prices: prices,
          },
          total_duration: convertToMinutes(combination.properties.duration),
          total_price: trainFareInNumber,
        };
      })
    );
    console.log(newData);

    return newData;
  }

  async function modifyTFTData(
    modeData: TrainFlightTrainModel[],
    mode: string
  ): Promise<TrainFlightTrainModel[]> {
    console.log(mode, modeData);
    if (!modeData) {
      console.log("No data available. for mode", mode);
      return []; // Early exit if flightTrainData is undefined or null
    }

    try {
      const newData = await Promise.all(
        modeData.map(async (combination, i) => {
          // const train1DurationInMinutes = convertToMinutes(
          //   combination.train1.properties.duration
          // );

          // const flightDurationInMinutes =
          //   combination.flight.properties.duration;

          // const train2DurationInMinutes = convertToMinutes(
          //   combination.train2.properties.duration
          // );
          // const buffer1 =
          //   (new Date(combination.flight.properties.departure_time) -
          //     new Date(combination.train1.properties.arrival_time)) /
          //   (1000 * 60);

          // const buffer2 =
          //   (new Date(combination.train2.properties.departure_time) -
          //     new Date(combination.flight.properties.arrival_time)) /
          //   (1000 * 60);

          // const totalDurationInMinutes =
          //   train1DurationInMinutes +
          //   train2DurationInMinutes +
          //   flightDurationInMinutes +
          //   buffer1 +
          //   buffer2;

          // console.log(totalDurationInMinutes);

          // const flightPriceInNumber = combination.flight.properties.price;

          // const prices1 = await getFares(
          //   combination.train1.properties.train_number,
          //   combination.train1.properties.station_from,
          //   combination.train1.properties.station_to
          // );
          // const prices2 = await getFares(
          //   combination.train2.properties.train_number,
          //   combination.train2.properties.station_from,
          //   combination.train2.properties.station_to
          // );

          // console.log(prices1, prices2);

          // const train1FareInNumber = getAvailableClass(prices1.general) || 0;
          // const train2FareInNumber = getAvailableClass(prices2.general) || 0;
          // const train1FareInNumber = 0;
          // const train2FareInNumber = 0;

          // const totalPrice =
          //   train1FareInNumber + train2FareInNumber + flightPriceInNumber;

          // console.log(totalPrice);

          const station1Names = await getStationNames(
            combination.train1.properties.station_from,
            combination.train1.properties.station_to
          );

          const station2Names = await getStationNames(
            combination.train2.properties.station_from,
            combination.train2.properties.station_to
          );

          const airportNames = await getAirportNames(
            combination.flight.properties.origin.display_code,
            combination.flight.properties.destination.display_code
          );

          return {
            ...combination,
            // total_duration: totalDurationInMinutes,
            train1: {
              ...combination.train1,
              properties: {
                ...combination.train1.properties,
                // prices: prices1,
                from_station_name: station1Names.origin_station_name,
                to_station_name: station1Names.destination_station_name,
                origin_city: station1Names.origin_city,
                destination_city: station1Names.destination_city,
              },
            },
            train2: {
              ...combination.train2,
              properties: {
                ...combination.train2.properties,
                // prices: prices2,
                from_station_name: station2Names.origin_station_name,
                to_station_name: station2Names.destination_station_name,
                origin_city: station2Names.origin_city,
                destination_city: station2Names.destination_city,
              },
            },
            flight: {
              ...combination.flight,
              properties: {
                ...combination.flight.properties,
                origin: {
                  ...combination.flight.properties.origin,
                  name: airportNames.origin_airport_name,
                },
                destination: {
                  ...combination.flight.properties.destination,
                  name: airportNames.destination_airport_name,
                },
                origin_city: airportNames.origin_city,
                destination_city: airportNames.destination_city,
              },
            },
            // total_price: totalPrice,
          };
        })
      );

      return newData;
    } catch (error: any) {
      console.error("Error processing data:", error.message);
      return modeData;
    }
  }

  async function modifyTFData(
    modeData: FlightTrainModel[],
    mode: string
  ): Promise<FlightTrainModel[]> {
    console.log(mode, modeData);
    if (!modeData) {
      console.log("No data available. for mode", mode);
      return []; // Early exit if flightTrainData is undefined or null
    }

    try {
      const newData = await Promise.all(
        modeData.map(async (combination, i) => {
          const trainDurationInMinutes = convertToMinutes(
            combination.train.properties.duration
          );

          const flightDurationInMinutes =
            combination.flight.properties.duration;

          const buffer =
            mode == "flightTrain"
              ? Math.abs(
                  (new Date(
                    combination.train.properties.departure_time
                  ).getTime() -
                    new Date(
                      combination.flight.properties.arrival_time
                    ).getTime()) /
                    (1000 * 60)
                )
              : Math.abs(
                  (new Date(
                    combination.flight.properties.departure_time
                  ).getTime() -
                    new Date(
                      combination.train.properties.arrival_time
                    ).getTime()) /
                    (1000 * 60)
                );

          const totalDurationInMinutes =
            trainDurationInMinutes + flightDurationInMinutes + buffer;

          // const flightPriceInNumber = parseInt(
          //   combination.flight.properties.price.replace(/[^\d]/g, ""),
          //   10
          // );

          const flightPriceInNumber = combination.flight.properties.price;

          // const prices = await getFares(
          //   combination.train.properties.train_number,
          //   combination.train.properties.station_from,
          //   combination.train.properties.station_to
          // );
          // const trainFareInNumber = getAvailableClass(prices.general) || 0;
          const trainFareInNumber = 0;

          const totalPrice = trainFareInNumber + flightPriceInNumber;

          const stationNames = await getStationNames(
            combination.train.properties.station_from,
            combination.train.properties.station_to
          );

          const airportNames = await getAirportNames(
            combination.flight.properties.origin.display_code,
            combination.flight.properties.destination.display_code
          );

          console.log("flights", airportNames);

          return {
            ...combination,
            total_duration: totalDurationInMinutes,
            train: {
              ...combination.train,
              properties: {
                ...combination.train.properties,
                from_station_name: stationNames.origin_station_name,
                to_station_name: stationNames.destination_station_name,
                origin_city: stationNames.origin_city,
                destination_city: stationNames.destination_city,
                // prices: prices,
              },
            },
            flight: {
              ...combination.flight,
              properties: {
                ...combination.flight.properties,
                origin: {
                  ...combination.flight.properties.origin,
                  name: airportNames.origin_airport_name,
                },
                destination: {
                  ...combination.flight.properties.destination,
                  name: airportNames.destination_airport_name,
                },
                origin_city: airportNames.origin_city,
                destination_city: airportNames.destination_city,
              },
            },
            total_price: totalPrice,
          };
        })
      );

      return newData;
    } catch (error) {
      console.error("Error processing data:", error);
      return modeData;
    }
  }

  async function modifyTTData(
    modeData: TrainTrainModel[]
  ): Promise<TrainTrainModel[]> {
    console.log(modeData);
    if (!modeData) {
      console.log("No data available. for ttmode");
      return []; // Early exit if flightTrainData is undefined or null
    }

    try {
      const newData = await Promise.all(
        modeData.map(async (combination, i) => {
          const train1DurationInMinutes = convertToMinutes(
            combination.train1.properties.duration
          );

          const train2DurationInMinutes = convertToMinutes(
            combination.train2.properties.duration
          );

          const buffer = Math.abs(
            (new Date(combination.train2.properties.departure_time).getTime() -
              new Date(combination.train1.properties.arrival_time).getTime()) /
              (1000 * 60)
          );

          const totalDurationInMinutes =
            train1DurationInMinutes + train2DurationInMinutes + buffer;

          const train1FareInNumber = 0;
          const train2FareInNumber = 0;

          const totalPrice = train1FareInNumber + train2FareInNumber;
          const stationNames1 = await getStationNames(
            combination.train1.properties.station_from,
            combination.train1.properties.station_to
          );
          const stationNames2 = await getStationNames(
            combination.train2.properties.station_from,
            combination.train2.properties.station_to
          );

          return {
            ...combination,
            total_duration: totalDurationInMinutes,
            total_price: totalPrice,
            train1: {
              ...combination.train1,
              properties: {
                ...combination.train1.properties,
                from_station_name: stationNames1.origin_station_name,
                to_station_name: stationNames1.destination_station_name,
                origin_city: stationNames1.origin_city,
                destination_city: stationNames1.destination_city,
              },
            },
            train2: {
              ...combination.train2,
              properties: {
                ...combination.train2.properties,
                from_station_name: stationNames2.origin_station_name,
                to_station_name: stationNames2.destination_station_name,
                origin_city: stationNames2.origin_city,
                destination_city: stationNames2.destination_city,
              },
            },
          };
        })
      );

      console.log(newData);

      return newData;
    } catch (error) {
      console.error("Error processing data:", error);
      return modeData;
    }
  }

  let airportNamesByCode: { [key: string]: string } = {};
  let cityNameAirByCode: { [key: string]: string } = {};
  async function getAirportNames(fromCode: string, toCode: string) {
    try {
      if (dummymode) {
        return {
          origin_station_name: "Failed to fetch",
          destination_station_name: "Failed to fetch",
        };
      }
      // 13.233.103.116
      if (airportNamesByCode[fromCode] && airportNamesByCode[toCode]) {
        console.log("found already existed for", fromCode, toCode);
        return {
          origin_airport_name: airportNamesByCode[fromCode],
          destination_airport_name: airportNamesByCode[toCode],
          origin_city: cityNameAirByCode[fromCode],
          destination_city: cityNameAirByCode[toCode],
        };
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/airport`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              origin_airport_code: fromCode,
              destination_airport_code: toCode,
            }),
          }
        );
        const result = await response.json();

        airportNamesByCode[fromCode] = result.origin_airport_name;
        airportNamesByCode[toCode] = result.destination_airport_name;
        cityNameAirByCode[fromCode] = result.origin_city;
        cityNameAirByCode[toCode] = result.destination_city;
        console.log(result.origin_city);
        console.log(result.destination_city);
        return result;
      }
    } catch (error) {
      console.error(error);
      return {
        origin: { airport_name: "Failed to fetch" },
        destination: { airport_name: "Failed to fetch" },
      };
    }
  }

  let stationNamesByCode: { [key: string]: string } = {};
  let cityNameByCode: { [key: string]: string } = {};
  //TODO: Optimise later by taking only one station at a time.
  async function getStationNames(fromCode: string, toCode: string) {
    try {
      if (dummymode) {
        return {
          origin_station_name: "Failed to fetch",
          destination_station_name: "Failed to fetch",
        };
      }

      if (stationNamesByCode[fromCode] && stationNamesByCode[toCode]) {
        console.log("found already existed for", fromCode, toCode);
        return {
          origin_station_name: stationNamesByCode[fromCode],
          destination_station_name: stationNamesByCode[toCode],
          origin_city: cityNameByCode[fromCode],
          destination_city: cityNameByCode[toCode],
        };
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/station`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              origin_station_code: fromCode,
              destination_station_code: toCode,
            }),
          }
        );
        const result = await response.json();
        console.log(result);

        stationNamesByCode[fromCode] = result.origin_station_name;
        stationNamesByCode[toCode] = result.destination_station_name;
        cityNameByCode[fromCode] = result.origin_city;
        cityNameByCode[toCode] = result.destination_city;
        return result;
      }
    } catch (error) {
      console.error(error);
      return {
        origin_station_name: "Failed to fetch",
        destination_station_name: "Failed to fetch",
      };
    }
  }

  //////////////////////////////////////////main return part ////////////////////////////////

  return (
    <div className="mixed">
      {/* TODO:ADD isSearchDisabled in searhbar component later */}
      <SearchBar
        fromCity={tempFromCity}
        toCity={tempToCity}
        setFromCity={setTempFromCity}
        setToCity={setTempToCity}
        fromState={tempFromState}
        toState={tempToState}
        setFromState={setTempFromState}
        setToState={setTempToState}
        departureDate={tempDepartureDate}
        setDepartureDate={setTempDepartureDate}
        adults={tempAdults}
        setAdults={setTempAdults}
        handleClickFunction={handleSearch}
      />

      <div className="main-frame">
        {/* <div className="input-container">
          <div className="input-upper">
            <div className="left-container">
              <div className="from-input">
                <div className="from">
                  <span className="from-1">From</span>
                </div>
                <div className="city-name">
                  <div className="city">
                    <input
                      className="vijayawada capitalize"
                      value={tempFromCity?.toLowerCase()}
                      onChange={handleFromCityChange}
                      type="text"
                      placeholder="Departure City"
                    />
                    {tempFromCity && (
                      <span
                        className="clear-cross"
                        onClick={() => clearInput("from")}
                      >
                        ×
                      </span>
                    )}
                    {fromSuggestions.length > 0 && (
                      <div className="suggestions">
                        <div className="top-search-1">Top searches</div>
                        {fromSuggestions.map((city, index) => (
                          <div
                            key={index}
                            className="suggestion capitalize"
                            onClick={() => selectCity(city, "from")}
                          >
                            {city.city_name?.toLowerCase()}
                            <div className="state-display">
                              {city.state_name?.toLowerCase()}, India
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {tempFromState && (
                    <div className="state">
                      <span
                        className="telangana-india capitalize"
                        title={`${tempFromState}, India`}
                      >
                        {tempFromCity &&
                          tempFromState?.toLowerCase() + ", India"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="from-to">
                <img className="icon" src={Icon_x2} alt="" />
              </div>
            </div>
            <div className="to-input">
              <div className="from-2">
                <span className="to">To</span>
              </div>
              <div className="city-name-1">
                <div className="city-1">
                  <input
                    className="lucknow capitalize"
                    value={tempToCity?.toLowerCase()}
                    onChange={handleToCityChange}
                    type="text"
                    placeholder="Destination City"
                  />
                  {tempToCity && (
                    <span
                      className="clear-cross"
                      onClick={() => clearInput("to")}
                    >
                      ×
                    </span>
                  )}
                  {toSuggestions.length > 0 && (
                    <div className="suggestions-1">
                      <div className="top-search-2">Top searches</div>
                      {toSuggestions.map((city, index) => (
                        <div
                          key={index}
                          className="suggestion-1 capitalize"
                          onClick={() => selectCity(city, "to")}
                        >
                          {city.city_name?.toLowerCase()}
                          <div className="state-display-1">
                            {city.state_name?.toLowerCase()}, India
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {tempToState && (
                  <div className="state-1">
                    <span
                      className="uttar-pradesh-india capitalize"
                      title={`${tempToState}, India`}
                    >
                      {tempToCity && tempToState?.toLowerCase() + ", India"}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="departure-input">
              <div className="from-3">
                <span className="departure">Departure</span>
              </div>
              <div className="city-name-2">
                <div className="city-2">
                  <div className="dateinput">
                    <DatePicker
                      format="D MMMM YYYY"
                      style={{
                        width: "100%",
                        border: "none",
                        fontFamily: "Poppins",
                        fontSize: 20,
                        padding: 0,
                        letterSpacing: "-0.4px",
                        margin: 0,
                        outline: "none",
                        boxShadow: "none",
                        color: "black",
                        background: "none",
                        cursor: "pointer",
                      }}
                      // range

                      numberOfMonths={2}
                      value={new Date(tempDepartureDate)}
                      minDate={new Date()}
                      onChange={handleDateChange}
                      className="teal"
                    />
                  </div>
                </div>
                <div className="state-2">
                  <span className="wednesday">
                    {formatDay(tempDepartureDate)}
                  </span>
                </div>
              </div>
            </div>

            <div
              className="adults-input"
              onClick={() => {
                setAdultsOpen(!adultsOpen);
              }}
            >
              <div className="from-4">
                <span className="travellers">Travellers</span>
              </div>
              <div className="city-name-3">
                <div className="city-3">
                  <span className="traveller">
                    {tempAdults === 1 ? (
                      <>{tempAdults} Traveller</>
                    ) : (
                      <>{tempAdults} Travellers</>
                    )}
                  </span>
                </div>
                <div className="state-3">
                  <span className="adult">
                    {tempAdults === 1 ? (
                      <>{tempAdults} Adult</>
                    ) : (
                      <>{tempAdults} Adults</>
                    )}
                  </span>
                </div>
              </div>
            </div>
            {adultsOpen && (
              <div className="adult-pop">
                <div className="containerx">
                  <p className="title">Adults</p>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        setTempAdults(tempAdults > 1 ? tempAdults - 1 : 1); // Ensure adults don't go negative
                      }}
                      className="button-top"
                    >
                      -
                    </button>
                    <p className="adult-count">
                      {tempAdults.toString().padStart(2, "0")}
                    </p>
                    <button
                      onClick={() => {
                        setTempAdults(
                          tempAdults <
                            parseInt(process.env.REACT_APP_MAX_ADULTS ?? "6")
                            ? tempAdults + 1
                            : tempAdults
                        );
                      }}
                      className="button-top"
                    >
                      +
                    </button>
                    <p className="adult-info">16+ years</p>
                  </div>

                  <div className="footer">
                    <button
                      onClick={() => {
                        setAdultsOpen(!adultsOpen);
                      }}
                      className="done-button"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="btn-wrap">
            <div className="just-type-in-city-name"></div>
            <button
              className={`search-button ${isSearchDisabled ? "disabled" : ""}`}
              onClick={() => (!isSearchDisabled ? handleSearch() : null)}
              style={{
                cursor: isSearchDisabled ? "not-allowed" : "pointer",
                opacity: isSearchDisabled ? 0.7 : 1,
              }}
            >
              <span className="search">Search</span>
            </button>
          </div>
        </div> */}

        <div className="cards-cont">
          {loading ? (
            <div className="skeleton w-[300px] min-w-[300px] min-h-[28px] mb-11 h-[32px] mt-[32px]"></div>
          ) : (
            <div className="paths-count">
              <span className="paths-for-you-to-travel">
                {totalPathsCount()} {totalPathsCount() <= 1 ? "Path" : "Paths"}{" "}
                for you to travel
              </span>
            </div>
          )}

          {loading ? (
            <>
              {Array(5)
                .fill(1)
                .map((_, i) => (
                  <div
                    key={i}
                    className="skeleton mx-[52px] min-h-[80px] mb-10 h-[100px]"
                  ></div>
                ))}
            </>
          ) : (
            initialTotal == 0 &&
            totalPathsCount() == 0 && (
              <div className="flex flex-col w-full items-center">
                {/* @ts-ignore */}
                <dotlottie-player
                  direction="1"
                  id="animation_map-with-pulsing-city-dots_e16d5fea-1165-11ee-8615-6bd07bc9a3fb"
                  speed="1"
                  mode="normal"
                  src="https://lottie.host/300b37f9-1a81-4db6-9d6d-f77c44a9ed3c/qrVRUnQ46W.json"
                  style={{
                    width: "200px",
                    margin: "-50px",
                    zIndex: 0,
                    height: "200px",
                  }}
                  autoplay
                  loop
                  // @ts-ignore
                ></dotlottie-player>
                <p className="z-10 text-lg font-semibold text-balance text-center">
                  Sorry, we couldn't find any results for your search based on
                  available trains, flights, or combinations. Please try
                  searching with a different date.
                </p>
              </div>
            )
          )}

          {!loading && trainData?.length > 0 && (
            <ModeCard
              mode="train"
              index={0}
              data={trainData}
              fromCity={fromCity}
              midCity={toCity}
              toCity={toCity}
              departureDate={departureDate}
              adults={adults}
              handleViewDetails={handleViewDetails}
            />
          )}
          {!loading && flightData?.length > 0 && (
            <ModeCard
              mode="flight"
              index={1}
              data={flightData}
              fromCity={fromCity}
              midCity={toCity}
              toCity={toCity}
              departureDate={departureDate}
              adults={adults}
              handleViewDetails={handleViewDetails}
            />
          )}
          {!loading &&
            Object.keys(flightTrainIntermediateObject).map((key, index) => (
              <ModeCard
                key={index}
                mode="flightTrain"
                index={index}
                data={
                  flightTrainIntermediateObject[key] as
                    | (DirectFlightModel | IndirectFlightModel)[]
                    | TrainModel[]
                    | FlightTrainModel[]
                    | TrainTrainModel[]
                }
                fromCity={fromCity}
                midCity={key}
                toCity={toCity}
                departureDate={departureDate}
                adults={adults}
                handleViewDetails={handleViewDetails}
              />
            ))}

          {!loading &&
            Object.keys(trainFlightIntermediateObject).map((key, index) => (
              <ModeCard
                key={index}
                mode="trainFlight"
                index={index}
                data={
                  trainFlightIntermediateObject[key] as
                    | (DirectFlightModel | IndirectFlightModel)[]
                    | TrainModel[]
                    | FlightTrainModel[]
                    | TrainTrainModel[]
                }
                fromCity={fromCity}
                midCity={key}
                toCity={toCity}
                departureDate={departureDate}
                adults={adults}
                handleViewDetails={handleViewDetails}
              />
            ))}

          {!loading &&
            Object.keys(trainTrainIntermediateObject).map((key, index) => (
              <ModeCard
                key={index}
                mode="trainTrain"
                index={index}
                data={
                  trainTrainIntermediateObject[key] as
                    | (DirectFlightModel | IndirectFlightModel)[]
                    | TrainModel[]
                    | FlightTrainModel[]
                    | TrainTrainModel[]
                }
                fromCity={fromCity}
                midCity={key}
                toCity={toCity}
                departureDate={departureDate}
                adults={adults}
                handleViewDetails={handleViewDetails}
              />
            ))}
          {!loading &&
            Object.keys(trainFlightTrainIntermediateObject).map(
              (key, index) => (
                <ModeCard
                  key={index}
                  mode="trainFlightTrain"
                  index={index}
                  data={trainFlightTrainIntermediateObject[key]}
                  fromCity={fromCity}
                  midCity={key}
                  toCity={toCity}
                  departureDate={departureDate}
                  adults={adults}
                  handleViewDetails={handleViewDetails}
                />
              )
            )}
          {Array(Math.max(initialTotal - totalPathsCount(), 0))
            .fill(1)
            .map((_, i) => (
              <div
                key={i}
                className="skeleton mx-[52px] min-h-[80px] mb-10 h-[100px]"
              ></div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ModesPage;
